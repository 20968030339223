import GooleIcon from "@/assets/icons/common/goole-icon.png"
import FacebookIcon from "@/assets/icons/common/facebook-icon.png"

export enum Login_Method {
  Google = 1,
  Facebook = 2
}

export enum VERIFY_TYPE {
  email = 1,
  phone = 2,
  password = 4
}

// 图标字典
export const IconDict = {
  1: GooleIcon,
  2: FacebookIcon
}

export enum LOGIN_SCENE {
  REGISTRY = 1,
  LOGIN = 2,
  EDIT_PSD = 3,
  FORGET_PSD = 4,
  DELETE_ACCOUNT = 8,
  REPEAT_AUTH = 9,
  CODE_LOGIN = 10,
  VERIFY_EMAIL_CODE = 11,
  RECOVER_EMAIL = 12
}

export const SocialName = {
  1: "Google",
  2: "Facebook"
}

// 忘记密码组件的类型
export enum IForgetPsdType {
  Type1 = 1, // 忘记密码
  Type2,
  Type3,
  Type4
}

export const IForgetPsdConfig = (type: IForgetPsdType) => {
  const dictConfig = reactive({
    [IForgetPsdType.Type1]: {
      // 忘记密码填写邮箱
      showForgetPsd: false,
      showForgetGoBack: true,
      showPsdTitle: true
    },
    [IForgetPsdType.Type2]: {
      // 忘记密码输入密码
      showForgetPsd: true,
      showForgetGoBack: true,
      showPsdTitle: true
    },
    [IForgetPsdType.Type3]: {
      //编辑密码输入密码
      showForgetPsd: true,
      showForgetGoBack: false,
      showPsdTitle: true
    },
    [IForgetPsdType.Type4]: {
      // fb填写邮箱
      showForgetPsd: false,
      showForgetGoBack: false,
      showPsdTitle: false
    }
  })
  return dictConfig[type]
}

export enum IErrorLoginType {
  AuthVerify = 5025,
  Retrieve = 5026,
  RepeatVerify = 5028,
  RedeemCode1 = 5029,
  RedeemCode2 = 5030,
  VerCode = 3004
}
// 注册来源类型
export enum SignFromType {
  Common = "0",
  Buy = "1",
  Export = "2"
}
