// 短链接页面组件和layout配置
export const landingShotConfig = () =>
  useState<{ com: any; layout: string; noShowHeadAndFooter?: boolean; resCom: any }>("landing_shot_config", () => {
    return {
      com: null,
      layout: null,
      resCom: null
    }
  })

export const landingShotParamsConfig = () =>
  useState<{
    sId?: string
    utm?: string
    kol?: string
    _uuid?: string
    [x: string]: any
  }>("landing_shot_params_config", () => {
    return {}
  })

export const landingShotPathConfig = () =>
  useState<string>("landing_shot_path_config", () => {
    return ""
  })

export const useGetLandingShotParams = () => {
  const query = { ...useRoute().query }
  for (const key in query) {
    query[key] = isArray(query[key]) ? query[key][0] : query[key]
  }
  return { ...query, ...landingShotParamsConfig().value }
}
