<!-- 下载高清图弹窗 -->
<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="download-img_dialog"
    :dialog-style="dialogStyle"
    :show-close-icon="showCloseIcon"
    @close="closeDialogFn"
  >
    <div class="download-img-container">
      <p class="title">{{ useTranslateI18n("webEditor.trial.downloadDialog.title") }}</p>
      <p class="t-2">{{ "HD " + resolution }}</p>
      <div class="img-preview">
        <CommonImage :src="currentImage.previewUrl" />
      </div>
      <CommonButton :button-style="buttonStyle" @btn-click="submitHandler"
        ><CommonAnimationScrollText>{{ btnText }} </CommonAnimationScrollText></CommonButton
      >
      <div class="tip">
        <span>{{
          useTranslateI18n("webEditor.trial.downloadDialog.t2", [
            subscription_info.total_credits - subscription_info.used_credits
          ])
        }}</span>
        <span class="link" @click="toTrialPackage">{{ useTranslateI18n("webEditor.trial.downloadDialog.t3") }}</span>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { SignFromType } from "@/constant/login"
import { useUser } from "~/store/user"
import { SessStorageKey } from "~/constant/cache"
import { EmitName } from "~/constant/eventBus"
const prop = defineProps({
  closeDialog: {
    type: Function,
    default() {
      return () => {}
    }
  },
  success: {
    type: Function,
    default() {
      return () => {}
    }
  },
  showCloseIcon: {
    type: Boolean,
    default() {
      return true
    }
  },
  image: {
    type: Object,
    default() {
      return {}
    }
  }
})

const dialogStyle = reactive({
  backgroundColor: "#fff"
})
const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})
const currentImage = computed(() => {
  if (prop.image.resolution) {
    return prop.image
  } else {
    const exportImage = JSON.parse(getLocalStorage(SessStorageKey.EXPORT_HD_IMADE, "sessionStorage") || "{}")
    return exportImage
  }
})
const resolution = computed(() => {
  return currentImage.value.resolution?.originalWidth + " * " + currentImage.value.resolution?.originalHeight
})

const use_user = useUser()
const { hasTrailCount } = use_user
const subscription_info = computed(() => {
  return use_user.subscription_info
})
//
const useTrialCount = computed(() => {
  return use_user.userInfo.trial_count
})
console.log("user subscription_info: ", subscription_info)
const btnText = computed(() => {
  if (hasTrailCount) {
    return useTranslateI18n("webEditor.trial.downloadDialog.btn2", [useTrialCount.value])
  } else {
    return useTranslateI18n("webEditor.trial.downloadDialog.btn")
  }
})
const { _pointFeatureDownLoadClick } = useNewFeatureTsSdk()
const submitHandler = async () => {
  if (hasTrailCount) {
    _pointFeatureDownLoadClick({ element_name: "export for free" })
  } else {
    _pointFeatureDownLoadClick({ element_name: "export for 1 credit" })
  }
  const { userInfo } = use_user
  const { id } = userInfo || {}
  const { md5, preview_type, sub_type, fileName } = currentImage.value as any
  const headers = {
    "File-Hash": md5
  }
  try {
    showDialog.value = false
    useEventBusEmit(EmitName.imageExporting, { isDoned: false })
    const data = await downloadHdImageApi(
      {
        preview_type,
        sub_type,
        user_id: id
      },
      headers
    )
    const url = blobToImageSrc(data)
    blobToFile(url, fileName)
    useEventBusEmit(EmitName.imageExporting, { isDoned: true })
    // 导出成功 更新张数
    // await use_user.setAllUserInfo()
  } catch (e) {
    useEventBusEmit(EmitName.imageExporting, { isDoned: true })
    console.log("error: ", e)
  }
}
const showDialog = ref(true)
const closeDialogFn = () => {
  showDialog.value = false
  prop.closeDialog && prop.closeDialog()
}
const toTrialPackage = () => {
  // showDialog.value = false
  _pointFeatureDownLoadClick({ element_name: "get credits" })
  useTrialPackageDialog({ fromType: SignFromType.Buy })
  const cacheImg = JSON.parse(getLocalStorage(SessStorageKey.EXPORT_HD_IMADE, "sessionStorage") || "{}")
  if (Object.keys(cacheImg).length < 1) {
    // 重新设置缓存
    setLocalStorage(SessStorageKey.EXPORT_HD_IMADE, JSON.stringify(currentImage.value), "sessionStorage")
  }
}
//初始化 更新张数
const initCredit = async () => {
  await use_user.setAllUserInfo()
}
onMounted(async () => {
  await initCredit()
})
</script>

<style lang="scss" scoped>
.download-img-container {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  .title {
    color: #000;
    // text-align: center;
    font-family: Roboto-Medium;
    font-size: 24px;
    // font-style: normal;
    margin-bottom: 8px;
  }
  .t-2 {
    color: rgba(0, 0, 0, 0.55);
    font-family: Roboto-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 40px;
  }
  .img-preview {
    width: 400px;
    height: 224px;
    border-radius: 6px;
    background: #f2f2f2;
    margin-bottom: 40px;
    overflow: hidden;
    :deep(.common-image) {
      object-fit: contain;
    }
  }
  .tip {
    margin-top: 16px;
    text-align: center;
    span {
      color: rgba(0, 0, 0, 0.7);
      text-align: center;
      font-family: Inter-Regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      &.link {
        color: #0057ff;
        cursor: pointer;
      }
    }
  }
  @include device-max-width-600 {
    padding: 24px;
    .title {
      font-size: 20px;
    }
    .t-2 {
      font-size: 14px;
      margin-bottom: 24px;
    }
    .img-preview {
      width: 220px;
      height: 140px;
      margin-bottom: 24px;
    }
    :deep(.common-button) {
      font-size: 12px !important;
      padding: 6px 10px;
      height: auto !important;
    }
  }
}
</style>
