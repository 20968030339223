import { tsSdkEventName } from "@/constant/enumtsskd"
import tsTrackingSdkInstance from "@/utils/tssdk"
const stSdkSetEvent = tsTrackingSdkInstance.setEvent
const ELEMENTCLICK = tsSdkEventName.ELEMENTCLICK
const PICTURESTAY = tsSdkEventName.PICTURESTAY
export const TS_SDK_BIZPARAMS = "$ts_sdk_ck_bizparams"

// 检查元素是否在视口内
function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}
// 获取元素相对于文档顶部的距离
function getElementOffsetTop(element) {
  let offset = 0
  while (element) {
    offset += element.offsetTop // 累加元素相对父元素的顶部偏移量
    element = element.offsetParent // 移动到父元素
  }
  return offset // 返回元素相对于文档顶部的距离
}
// 获取当前屏幕内的section (根据 H1 和 H2 ）
function getVisibleSection(): string {
  const headers = document.querySelectorAll("h1, h2")
  for (const [index, value] of headers.entries()) {
    if (isElementInViewport(value)) {
      const section = value as unknown as HTMLElement
      return section.dataset["section"] || `section_${index + 1}` // 默认section_index
    }
  }
  // 未出现标题(个别情况) 对比高度距离
  const offsetTopMap = []
  headers.forEach((header, index) => {
    const offsetTop = getElementOffsetTop(header)
    offsetTopMap[index] = offsetTop
  })
  const currentScrollTop = window.scrollY || document.documentElement.scrollTop
  const index = offsetTopMap.findIndex((offsetTop) => currentScrollTop < offsetTop)
  // console.log("未出现标题(个别情况)", index, offsetTopMap)
  return index === 1 ? "hero_section" : `section_${index}`
}
// 记录当前section
function recordCurrentSection() {
  const currentSection = getVisibleSection()
  // console.log("当前section:", currentSection)
  if (currentSection) {
    // 在这里执行你想要的操作，比如记录当前section
    const biz_params = JSON.parse(getCookie(TS_SDK_BIZPARAMS) || "{}")
    const params = {
      ...biz_params,
      action_value_string: currentSection
    }
    setCookie(TS_SDK_BIZPARAMS, JSON.stringify(params))
  }
}
// 记录当前位置埋点
export const _pointFeatureSection = () => {
  const _recordCurrentSection = useDebounceFn(() => {
    recordCurrentSection()
  }, 500)
  onMounted(() => {
    recordCurrentSection()
    window.addEventListener("scroll", _recordCurrentSection)
  })
  onBeforeUnmount(() => {
    window.removeEventListener("scroll", _recordCurrentSection)
    clearCookie(TS_SDK_BIZPARAMS)
  })
}
export const useNewFeatureTsSdk = () => {
  //点击feature跳转下载
  const _pointFeatureDownLoadClick = (pop) => {
    stSdkSetEvent(ELEMENTCLICK, {
      element_position: "hero_section",
      element_type: "button",
      element_name: "download",
      ...pop
    })
  }
  const _pointPictureSlider = (pop) => {
    stSdkSetEvent(PICTURESTAY, {
      element_position: "hero_section",
      element_type: "slider",
      element_name: "slider",
      action_duration: 0,
      ...pop
    })
  }
  return {
    _pointFeatureDownLoadClick,
    _pointPictureSlider,
    _pointFeatureSection
  }
}
