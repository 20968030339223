import http from "@/http/index"
import { commonApi } from "@/constant/api/common"

/**
 * @description features试用API
 */

// 去背景API
export const backgroundRemovalApi = async (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=1", args, {
    headers
  })
}
// 换发色API
export const changeHairColorApi = (args: FormData, headers?: object, sub_type = 1) => {
  return http.post(process.env.Feature_Image_Upload + `?preview_type=2&sub_type=${sub_type}`, args, {
    headers
  })
}
// 眼镜去反光API
// export const glareRemovalApi = (args: FormData, headers?: object) => {
//   return http.post(process.env.Feature_Image_Upload + "?preview_type=4", args, {
//     headers
//   })
// }
// 眼镜去反光API
export const glareRemovalApi = (args: FormData, headers?: object, onProcess?: (event: ProgressEvent) => void) => {
  return http.xhrPost(
    process.env.Feature_Image_Upload + "?preview_type=4",
    args,
    {
      headers
    },
    false,
    onProcess
  )
}

// blemishRemoval API
export const blemishRemovalApi = (args: FormData, headers?: object, onProcess?: (event: ProgressEvent) => void) => {
  return http.xhrPost(
    process.env.Feature_Image_Upload + "?preview_type=3",
    args,
    {
      headers
    },
    false,
    onProcess
  )
}
// 证件照优化API
export const headShotApi = async (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=5", args, {
    headers
  })
}
// 加微笑API
export const smileGenerationApi = async (
  args: FormData,
  headers?: object,
  onProcess?: (event: ProgressEvent) => void
) => {
  return http.xhrPost(
    process.env.Feature_Image_Upload + "?preview_type=6",
    args,
    {
      headers
    },
    false,
    onProcess
  )
}
// 图片裁剪API(纯加水印)
export const imageCropperApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=7", args, {
    headers
  })
}
// 去褶皱 API
export const removeWrinklesApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=8", args, {
    headers
  })
}
// 换天空 API
export const changeSkyApi = (args: FormData, headers?: object, sub_type = 1) => {
  return http.post(process.env.Feature_Image_Upload + `?preview_type=9&sub_type=${sub_type}`, args, {
    headers
  })
}
// AI追色API
export const colorMatchApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=10", args, {
    headers
  })
}
// 换背景 API
export const changeBackgroundApi = (args: FormData, headers?: object, sub_type = 1) => {
  return http.post(process.env.Feature_Image_Upload + `?preview_type=11&sub_type=${sub_type}`, args, {
    headers
  })
}
// 去纹身API
export const removeTattooApi = (args: FormData, headers?: object, onProcess?: (event: ProgressEvent) => void) => {
  return http.xhrPost(
    process.env.Feature_Image_Upload + "?preview_type=12",
    args,
    {
      headers
    },
    false,
    onProcess
  )
}
// 背景去瑕疵
export const cleanBackgroundApi = (args: FormData, headers?: object, onProcess?: (event: ProgressEvent) => void) => {
  return http.xhrPost(
    process.env.Feature_Image_Upload + "?preview_type=13",
    args,
    {
      headers
    },
    false,
    onProcess
  )
}
// 化妆
export const makeupEditorApi = (args: FormData, headers?: object) => {
  return http.post(process.env.Feature_Image_Upload + "?preview_type=14", args, {
    headers
  })
}
// 上传原图
export const uploadOriginImageApi = (args: FormData, headers?: object) => {
  return http.post(process.env.REALTIME_URL + commonApi.WEB_UPlOAD, args, {
    headers
  })
}

// 下载高清图
export const downloadHdImageApi = (params: object, headers?: object) => {
  return http.post(
    process.env.REALTIME_URL + commonApi.WEB_EXPORT,
    { ...params },
    {
      headers
    }
  )
}
// 上报反馈
export const reportFeedbackApi = (params: object) => {
  return http.post(commonApi.FEEDBACK_REPORT, { ...params })
}

// 下载Preview
export const downloadPreviewApi = (params: object, headers?: object) => {
  return http.post(
    process.env.REALTIME_URL + commonApi.WEB_EXPORT_PREVIEW,
    { ...params },
    {
      headers
    }
  )
}
