<template>
  <div ref="imgWarp" class="img-load">
    <img
      ref="imgRef"
      :class="['common-image', { hide: loading }]"
      v-bind="{ ...attrsBindData, ...props, ...refData }"
      @load="imgLoad"
      @error="imgError"
    />
    <!-- <NuxtImg
      ref="imgRef"
      :class="['common-image', { hide: loading }]"
      v-bind="{ ...attrsBindData, ...props, ...refData }"
      @load="imgLoad"
      @error="imgError"
    /> -->
    <div v-if="loading" :class="['common-loading', $attrs.class]" :style="loadingStyle"></div>
  </div>
</template>
<script lang="ts" setup>
import { StyleValue } from "vue"

interface commonImageProp {
  src?: string
  lazy?: boolean
  preloadDuring?: string
  background?: string
}

const props = withDefaults(defineProps<commonImageProp>(), {
  src: "",
  lazy: false,
  preloadDuring: "0px",
  background: "linear-gradient(90deg, #232628 25%, #2D3031 37%, #232628 63%)"
})
const refData = reactive<any>({
  src: props.lazy ? "" : props.src
  // src: ""
})

const loading = ref<boolean>(props.lazy)
const loadingStyle = ref<StyleValue>()
let timeout: any
const during = 10000

const attrs = useAttrs()

const showImg = ref<boolean>(false)

const imgWarp = ref<HTMLElement>(null)

const emit = defineEmits(["lazyLoadSuccess", "lazyLoadFail", "imageLoaded"])

watch(
  () => props.src,
  (newData: any, oldData) => {
    if (showImg) {
      refData.src = newData
    }
  }
)
const attrsBindData = computed(() => {
  let data = { ...attrs }
  if (data.style) {
    delete data.style
  }
  if (!data.alt) {
    data.alt = "AI photo editor"
  }
  return data
})

const imgRef = ref()

let imgLoad = ref()
let imgError = ref()

onMounted(() => {
  loadingStyle.value = {
    background: props.background
  }
  if (!props.lazy) {
    imgLoad.value = () => {
      clearTimeout(timeout)
      loading.value = false
      emit("imageLoaded")
    }
    imgError.value = () => {
      clearTimeout(timeout)
      loading.value = false
    }
    timeout = setTimeout(() => {
      imgError.value()
    }, during)
    showImg.value = true
    refData.src = props.src
    return
  }

  useDomObserver(imgRef.value, () => {
    showImg.value = true
    refData.src = props.src
    imgLoad.value = () => {
      clearTimeout(timeout)
      loading.value = false
      emit("lazyLoadSuccess")
      emit("imageLoaded")
    }
    imgError.value = () => {
      clearTimeout(timeout)
      loading.value = false
      emit("lazyLoadFail")
    }
    timeout = setTimeout(() => {
      imgError.value()
    }, during)
  })
})

onBeforeMount(() => {
  clearTimeout(timeout)
})

defineExpose({
  image: imgRef
})
</script>

<style lang="scss" scoped>
.img-load {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .common-image {
    width: 100%;
    height: 100%;
  }
  .common-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #232628 25%, #2d3031 37%, #232628 63%);
    background-size: 400% 100% !important;
    animation: skeleton-loading 1.4s ease infinite;
    border-radius: inherit;
  }
  .hide {
    opacity: 0;
  }
  .aspect-fit {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .height-fit {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
  .width-fit {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .aspect-fill {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
