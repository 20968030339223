import http from "@/http/index"
import { profileApi } from "@/constant/api/profile"
import { SubStatus } from "@/composables/api/common"
import { IComboProductByMyAvailableState } from "@/composables/api/combo"
import http2 from "~~/http/http2"
import { TicketStatus } from "~/constant/pages/profile"

interface IMySubscription {
  downgraded_plan_name: string
  is_canceled: boolean
  is_downgraded: boolean
  name: string
  payment_info: {
    card_type: string
    last_four_digits: string
    expiry_date: string
  }
  status: SubStatus // 1 正常 4 暂停或者过期
  total_credits: number
  used_credits: number
}

export enum ISubPaymentStatus {
  Paid = "paid",
  Failed = "failed",
  Due = "due",
  Refunded = "refunded"
}

export interface ISubPaymentObj {
  amount: string
  billing_date: string
  id: number
  receipt_url: string
  status: ISubPaymentStatus
  trade_no: string
  is_redemption_code?: boolean
  [x: string]: any
}

interface IOtherPackagesObj {
  expired_date: string
  name: string
  tag: string
  total_credits: number
  used_credits: number
  state?: IComboProductByMyAvailableState
}

export type IMySubscriptionData = {
  my_subscription?: IMySubscription
  next_bill_date?: string
  next_get_credits?: number
  other_packages?: IOtherPackagesObj[]
  payment?: ISubPaymentObj[]
  total_credits?: number
  update_payment_url?: string
  used_credits?: number
}

export interface IIMachineListObj {
  id: number
  uuid: number | string
  platform: number
  info: string
  is_system: number
  is_bad: number
  last_active_time: string
  [x: string]: any
}

export interface IProcessDetailListInfo {
  accounts: string
  cost_num: string
  created_at: string
  finish_num: number
  projects: string
  id: any
}

export type IIMachineList = { machine_list: Partial<IIMachineListObj>[]; limit: number }

export enum IPostOffReason {
  Type1 = 1,
  Type2 = 2,
  Type3 = 3,
  Type4 = 4,
  Type5 = 5,
  Type6 = 6
}

export type IPostLogOffUser = {
  code: string
  reason: IPostOffReason
  reason_text: string
  is_delete_org: boolean
  email?: string
  phone?: string
  phone_cc?: string
}

export type IReceiptInfo = {
  title: string
  address: string
  phone_number: string
  phone_code: string
  email_address: string
  tax_code?: string
}

export type ProcessInfo = {
  content: string
  updated: string
}

export type TicketsInfo = {
  id: number
  status: TicketStatus
  subject: string
  email: string
  updated_time: string
  received_time: string
  resolved?: ProcessInfo[]
  resolved_time?: string
  in_process?: ProcessInfo[]
  in_process_time?: string
}

// 获取我的订阅
export const getMySubscriptionApi = <T = IMySubscriptionData>() => {
  return http.get<T>(profileApi.MY_SUBSCRIPTION)
}

// 获取活跃机器列表
export const getMachineList = <T = IIMachineList>() => {
  return http.get<T>(profileApi.GET_MACHINE_LIST)
}

// 用户下线机器
export const removeMachineApi = <T = any>(data: { machine_id_list: number[] }) => {
  return http.post<T>(profileApi.REMOVE_MACHINE, data)
}

// 获取导出列表
export const getProcessDetailList = <T = IProcessDetailListInfo[]>(data: {
  time_range: string[]
  page_size: number
  page: number
}) => {
  return http.post<T>(profileApi.GET_PROCESS_DETAIL_LIST, data)
}

export const getProcessDetailList2 = <T = IProcessDetailListInfo[]>(data: {
  time_range: string[]
  page_size: number
  page: number
}) => {
  return http2.post<T>(profileApi.GET_PROCESS_DETAIL_LIST, data)
}

// 获取导出明细
export const getProcessDetailListInfo = <T = any>(id: any) => {
  return http.post<T>(profileApi.GET_PROCESS_DETAIL_LIST_D, { id })
}

// 注销用户
export const postLogoffUser = <T = { log_off_now_token: string }>(options: IPostLogOffUser) => {
  return http.post<T>(profileApi.LOG_OFF_USER, options)
}

// 立即注销用户
export const postLogOffNow = <T = any>(token: string) => {
  return http.post<T>(profileApi.POST_LOG_OFF_NOW, { token })
}

// 获取完善信息
export const getReceiptInfo = <T = IReceiptInfo>() => {
  return http.get<T>(profileApi.GET_RECEIPT_INFO)
}

// 完善开票信息
export const postReceiptInfo = <T = any>(options: IReceiptInfo) => {
  return http.post<T>(profileApi.POST_RECEIPT_INFO, options)
}

// 开票
export const postReceiptCreate = <T = any>(options: { trade_no: string }) => {
  return http.post<T>(profileApi.POST_RECEIPT_CREATE, options)
}

export const postTicketsList = <T = TicketsInfo[]>() => {
  return http.get<T>(profileApi.GET_TICKETS)
}
