<!-- 评分反馈弹窗 -->
<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="feedback_dialog"
    :dialog-style="dialogStyle"
    :show-close-icon="showCloseIcon"
    @close="closeDialogFn"
  >
    <div class="feedback-form">
      <p class="title">{{ useTranslateI18n("webEditor.feedback.form.title") }}</p>
      <p class="desc">{{ useTranslateI18n("webEditor.feedback.form.desc") }}</p>
      <div class="form-box">
        <div v-for="type in [1, 2]" :key="type" class="case-item">
          <p class="case-type">{{ useTranslateI18n(`webEditor.feedback.form.t${type}`) }}</p>
          <p v-for="i in filterCase(type)" :key="i" class="case-detail">
            <el-checkbox v-model="i.check" />
            {{ i.name }}
          </p>
        </div>
        <el-input
          v-model="textIssues"
          maxlength="200"
          :autosize="{ minRows: 4, maxRows: 6 }"
          type="textarea"
          :placeholder="useTranslateI18n(`webEditor.feedback.form.placeholder`)"
        />
      </div>
      <el-tooltip
        v-if="!hasChecked"
        class="box-item"
        effect="dark"
        :content="useTranslateI18n(`webEditor.feedback.form.t3`)"
        placement="top"
      >
        <div class="disable-btn">
          <CommonButton :button-style="buttonStyle" @btn-click="submitHandler"
            ><CommonAnimationScrollText
              >{{ useTranslateI18n("webEditor.feedback.form.btn") }}
            </CommonAnimationScrollText></CommonButton
          >
        </div>
      </el-tooltip>
      <CommonButton v-else :button-style="buttonStyle" @btn-click="submitHandler"
        ><CommonAnimationScrollText
          >{{ useTranslateI18n("webEditor.feedback.form.btn") }}
        </CommonAnimationScrollText></CommonButton
      >
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { EnumType } from "~~/constant/api/common"
import { useUser } from "~/store/user"
import { featureListDict } from "@/constant/navbar"
const prop = defineProps({
  closeDialog: {
    type: Function,
    default() {
      return () => {}
    }
  },
  success: {
    type: Function,
    default() {
      return () => {}
    }
  },
  showCloseIcon: {
    type: Boolean,
    default() {
      return true
    }
  },
  image: {
    type: Object,
    default() {
      return {}
    }
  }
})
const dialogStyle = reactive({
  backgroundColor: "#fff"
})
const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})
const showDialog = ref(true)
const textIssues = ref("")
const closeDialogFn = () => {
  showDialog.value = false
  prop.closeDialog && prop.closeDialog()
}
const caseList = ref([])
const initCaseList = async () => {
  let data = await getEnumList(EnumType.Feedback)
  caseList.value = data.map((item) => {
    return {
      ...item,
      check: false
    }
  })
}
const filterCase = (subType: number) => {
  return caseList.value.filter((item: any) => item.sub_type === subType).sort((a: any, b: any) => a.sort - b.sort)
}
const hasChecked = computed(() => {
  return caseList.value.some((item: any) => item.check) || textIssues.value.trim() !== ""
})
const getSubject = (key: string) => {
  return featureListDict().find((item) => item.key === key)?.enName || ""
}
const submitHandler = () => {
  const { userEmail } = useUser()
  const subType = caseList.value.filter((item: any) => item.check).map((item: any) => item.id)
  const { md5 = "", src = "", preview_type = "", sub_type = "", token = "" } = prop.image
  try {
    const params = {
      email: userEmail,
      url: src,
      subtype: subType,
      message: textIssues.value.trim(),
      md5,
      preview_type,
      sub_type,
      subject: getSubject(token)
    }
    if (md5) {
      // 有md5 不用url 避免请求过大
      params.url = ""
    }
    reportFeedbackApi(params)
    closeDialogFn()
  } catch (e) {
    console.error(e)
    closeDialogFn()
  }
}
initCaseList()
</script>

<style lang="scss" scoped>
.feedback_dialog {
  .feedback-form {
    padding: 40px;
    .title {
      color: #000;
      font-family: Roboto-Bold;
      font-size: 24px;
      margin-bottom: 16px;
    }
    .desc {
      color: rgba(0, 0, 0, 0.55);
      font-family: Roboto-Regular;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 24px;
    }
    .form-box {
      margin-bottom: 40px;
      .case-item {
        margin-bottom: 24px;
        .case-type {
          color: #000;
          font-family: Roboto-Bold;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .case-detail {
          color: rgba(0, 0, 0, 0.55);
          font-family: Roboto-Regular;
          font-size: 12px;
          font-style: normal;
          line-height: normal;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
          .el-checkbox {
            height: auto;
            vertical-align: middle;
            margin-right: 8px;
          }
        }
      }
      :deep(.el-textarea) {
        .el-textarea__inner {
          padding: 12px;
          background-color: #f5f5f5;
        }
      }
    }
    :deep(.disable-btn) {
      opacity: 0.5;
      cursor: not-allowed;
      .common-button {
        pointer-events: none;
      }
    }
  }
  @include device-max-width-600 {
    .feedback-form {
      padding: 20px;
      .title {
        font-size: 20px;
      }
      .desc {
        font-size: 14px;
        margin-bottom: 16px;
      }
      .form-box {
        .case-item .case-type {
          font-size: 14px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.feedback_dialog {
  @include device-max-width-900 {
    .content {
      width: 80%;
      .content-d {
        width: 100% !important;
      }
    }
  }
}
</style>
