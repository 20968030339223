export default defineNuxtPlugin({
  name: "global-set",
  dependsOn: ["i18n-f"],
  async setup(nuxtApp) {
    const { isInlineClient } = useClientBridgeRouteGuard()
    const { language } = (nuxtApp.$_need_init_data || {}) as any

    const path = nuxtApp._route.path
    // 如果是落地页的话不走自动浏览器检测
    if (
      (language && useIsLandingByLanguage(path)) ||
      (language && isInlineClient) ||
      (language && useIsSensitiveLogin(path))
    ) {
      await setI18nLocale(nuxtApp, language)
    }
  }
})
