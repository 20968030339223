import { ILocaleValue } from "@/constant/i18n-country"

export default defineNuxtPlugin({
  name: "referral",
  dependsOn: ["global-set"],
  async setup(nuxtApp) {
    try {
      const data = await getReferralWebsiteConfig()
      const urlObj = new URL(data.landing_link)
      const languagePrefix = useI18nCountry().value === ILocaleValue.en ? "" : `${useI18nCountry().value}`
      urlObj.pathname = `${languagePrefix}${urlObj.pathname}`
      data.landing_link = urlObj.toString()
      useReferrerConfigData().value = data
    } catch (e) {}
  }
})
