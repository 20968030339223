// 获取dom 距离顶部距离
export function getElementToPageTop(el: HTMLElement) {
  if (el.parentElement) {
    return getElementToPageTop(el.parentElement) + el.offsetTop
  }
  return el.offsetTop
}

export function stringToBytes(str) {
  const bytes = []
  let c
  const len = str.length
  for (let i = 0; i < len; i++) {
    c = str.charCodeAt(i)
    if (c >= 0x010000 && c <= 0x10ffff) {
      bytes.push(((c >> 18) & 0x07) | 0xf0)
      bytes.push(((c >> 12) & 0x3f) | 0x80)
      bytes.push(((c >> 6) & 0x3f) | 0x80)
      bytes.push((c & 0x3f) | 0x80)
    } else if (c >= 0x000800 && c <= 0x00ffff) {
      bytes.push(((c >> 12) & 0x0f) | 0xe0)
      bytes.push(((c >> 6) & 0x3f) | 0x80)
      bytes.push((c & 0x3f) | 0x80)
    } else if (c >= 0x000080 && c <= 0x0007ff) {
      bytes.push(((c >> 6) & 0x1f) | 0xc0)
      bytes.push((c & 0x3f) | 0x80)
    } else {
      bytes.push(c & 0xff)
    }
  }
  return bytes
}

export function byteToString(arr) {
  if (typeof arr === "string") {
    return arr
  }
  let str = ""
  const _arr = arr
  for (let i = 0; i < _arr.length; i++) {
    const one = _arr[i].toString(2),
      v = one.match(/^1+?(?=0)/)
    if (v && one.length == 8) {
      const bytesLength = v[0].length
      let store = _arr[i].toString(2).slice(7 - bytesLength)
      for (let st = 1; st < bytesLength; st++) {
        store += _arr[st + i].toString(2).slice(2)
      }
      str += String.fromCharCode(parseInt(store, 2))
      i += bytesLength - 1
    } else {
      str += String.fromCharCode(_arr[i])
    }
  }
  return str
}

//设置cookie
export function setCookie(
  cname,
  cvalue,
  exdays = 30,
  _opt?: {
    domain?: string
    path?: string
  }
) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = "expires=" + d.toUTCString()
  let cookieString = ""
  if (_opt) {
    cookieString += _opt.path ? `; path=${_opt.path}` : ""
    cookieString += _opt.domain ? `; domain=${_opt.domain}` : ""
  }
  document.cookie = cname + "=" + cvalue + "; " + expires + cookieString
}

// 删除cookie
export function clearCookie(name) {
  setCookie(name, "", -1)
}

export const getCookie = (name) => {
  name = `${encodeURIComponent(name)}`
  const cookies = document.cookie.split("; ")
  for (const item of cookies) {
    const [cookieName, cookieValue] = item.split("=")
    if (cookieName === name || cookieName === decodeURIComponent(name)) {
      return decodeURIComponent(cookieValue)
    }
  }
  return undefined
}

// 首字母大写
export function titleCase(str: string) {
  return str.slice(0, 1).toUpperCase() + str.slice(1)
}

function copyContent(text: string) {
  let target = null

  target = document.createElement("div")
  target.id = "tempTarget"
  target.style.opacity = "0"
  target.innerText = text
  document.body.appendChild(target)

  try {
    const range = document.createRange()
    range.selectNode(target)
    window.getSelection()?.removeAllRanges()
    window.getSelection()?.addRange(range)
    document.execCommand("copy")
    window.getSelection()?.removeAllRanges()
  } catch (e) {
    console.log(e)
  }
  target.parentElement?.removeChild(target)
}
export async function copy(text: string) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    await navigator.clipboard.writeText(text) // Promise<void>
  } else {
    copyContent(text)
  }
}

// 随机50%
export function fiftyPercentChance() {
  // 生成一个范围在 [0, 1) 的随机数
  const randomValue = Math.random()
  // 如果随机数小于0.5，返回true；否则返回false
  return randomValue < 0.5
}

export async function useSha256(str) {
  // 将字符串转换为 ArrayBuffer
  const buffer = new TextEncoder().encode(str)

  // 计算 SHA-256 哈希值
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer)

  // 将 ArrayBuffer 转换为十六进制字符串
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("")

  return hashHex
}
export function isNthBinaryDigitOne(number, n) {
  // 将数字转换为二进制字符串
  const binaryStr = number.toString(2)
  // 获取二进制字符串的第 n 位字符（从右往左，最低位为第一位）
  const nthBinaryDigit = binaryStr.charAt(binaryStr.length - n)
  // 判断第 n 位字符是否是 '1'
  return nthBinaryDigit === "1"
}

// 36进制转10进制
export function base36ToDecimal(base36Number) {
  // 定义36进制字符到十进制数值的映射关系
  const base36Chars = "0123456789abcdefghijklmnopqrstuvwxyz"
  const base36Values = {}

  // 初始化映射关系
  for (let i = 0; i < base36Chars.length; i++) {
    base36Values[base36Chars[i]] = i
  }

  // 将36进制数转换为十进制数
  let decimalNumber = 0
  const base = 36

  for (let i = 0; i < base36Number.length; i++) {
    const char = base36Number[i]
    const value = base36Values[char]
    decimalNumber += value * Math.pow(base, base36Number.length - 1 - i)
  }
  return decimalNumber
}
// 前端下载二进制
export const downloadFile = async (blob: Blob, filename: string) => {
  const fileSaver = (await import("file-saver")).default
  fileSaver(blob, filename)
}

// 获取一级域名
export function extractRootDomain(url) {
  const { hostname } = new URL(url)
  const domainParts = hostname.split(".").reverse()
  if (domainParts.length >= 3 && domainParts[1].length <= 3 && domainParts[2].length > 3) {
    return domainParts[2] + "." + domainParts[1] + "." + domainParts[0]
  } else if (domainParts.length >= 2) {
    return domainParts[1] + "." + domainParts[0]
  }
  return hostname
}

export function redirectWithUrl(baseUrl, queryParams = {}) {
  // 创建 URL 对象
  const url = new URL(baseUrl)
  // 遍历 queryParams 对象，添加所有的查询参数
  for (const [key, value] of Object.entries(queryParams)) {
    // 对参数值进行编码，并添加到 URL 的查询参数中
    url.searchParams.append(key, encodeURIComponent(value as any))
  }
  // 将新的 URL 赋值给 location.href，触发页面重定向
  return url.toString()
}

export function convertToString(input) {
  if (input === null) {
    return "null"
  }
  if (input === undefined) {
    return "undefined"
  }
  if (typeof input === "object") {
    try {
      return JSON.stringify(input)
    } catch (e) {
      return input.toString()
    }
  }
  return input.toString()
}

// deep link 跳转
export function openApp(platform: ICommonGroupLink) {
  const deepLinkDict = {
    [ICommonGroupLink.Ins]: {
      appLink: "instagram://",
      webLink: "https://www.instagram.com/"
    },
    [ICommonGroupLink.Facebook]: {
      appLink: "fb://",
      webLink: "https://www.facebook.com/"
    },
    [ICommonGroupLink.Kakao]: {
      appLink: "kakaotalk://",
      webLink: "https://www.kakao.com"
    },
    [ICommonGroupLink.Line]: {
      appLink: "line://",
      webLink: "https://line.me/"
    },
    [ICommonGroupLink.Twitter]: {
      appLink: "twitter://",
      webLink: "https://twitter.com/"
    },
    [ICommonGroupLink.Whatsapp]: {
      appLink: "whatsapp://",
      webLink: "https://www.whatsapp.com/"
    }
  }
  const { appLink, webLink } = deepLinkDict[platform] || {}

  let hidden = false
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  // 监听是否成功打开 App
  document.addEventListener("visibilitychange", function () {
    hidden = document.hidden
  })

  if (/android/i.test(userAgent)) {
    window.location.href = appLink
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    window.location.href = appLink
  } else {
    window.location.href = appLink
    return
  }

  // 2 秒后检查是否打开 App，失败则跳转到 Web 版
  setTimeout(function () {
    if (!hidden) {
      window.location.href = webLink
    }
  }, 2000)
}

export const getAssociationAppIcon = (appArr: ICommonGroupLink[]) => {
  const appIconDict = {
    [ICommonGroupLink.Ins]: {
      src: getCloudAssets(`/icons/common/Instagram_icon.png`)
    },
    [ICommonGroupLink.Facebook]: {
      src: getCloudAssets(`/icons/common/facebook-icon-2.png`)
    },
    [ICommonGroupLink.Kakao]: {
      src: getCloudAssets(`/icons/common/kakao-icon.png`)
    },
    [ICommonGroupLink.Line]: {
      src: getCloudAssets(`/icons/common/line-icon-2.png`)
    },
    [ICommonGroupLink.Twitter]: {
      src: getCloudAssets(`/icons/common/twitter-icon-2.png`)
    },
    [ICommonGroupLink.Whatsapp]: {
      src: getCloudAssets(`/icons/common/whats_app_icon.png`)
    }
  }

  const resAppDict = {}

  appArr.forEach((_) => {
    resAppDict[_] = appIconDict[_]
  })

  return resAppDict
}
