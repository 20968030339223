import SparkMD5 from "spark-md5"
export async function compressAndReturnFile(
  initFile: File,
  maxWidth: number,
  outputFormat: string,
  quality: number,
  callback: (
    file: File,
    url?: string,
    exifData?: any,
    resolution?: { width: number; height: number; originalWidth: number; originalHeight: number }
  ) => any,
  maxResolution?: number
) {
  let resizeCount = 0
  let exifData = null
  let originalWidth, originalHeight
  function compressStep(recordCount: boolean, file: File, url = "") {
    const reader = new FileReader()
    reader.onload = function (event) {
      const img = new Image()
      img.onload = function () {
        function executeResize() {
          if (maxResolution) {
            // 分辨率限制
            if (img.width * img.height <= maxResolution) {
              callback(file, url || img.src, exifData, {
                width: img.width,
                height: img.height,
                originalWidth,
                originalHeight
              })
            } else {
              // 计算缩放比例，确保缩放后的像素数不超过最大像素限制
              const scaleFactor = Math.sqrt(maxResolution / (img.width * img.height))
              // 计算新的宽度和高度
              const newWidth = Math.round(img.width * scaleFactor)
              const newHeight = Math.round(img.height * scaleFactor)

              canvas.width = newWidth
              canvas.height = newHeight
              ctx.drawImage(img, 0, 0, newWidth, newHeight)
              // 获取压缩后的图像数据URL
              const compressedDataURL = canvas.toDataURL(outputFormat, quality)
              // 将数据URL转换为File对象
              const compressedFile = dataURLtoFile(compressedDataURL, initFile.name)
              compressStep(false, compressedFile, compressedDataURL)
            }
          } else {
            // 最长边限制
            if (!resizeCount || maxImgLength === maxWidth) {
              callback(file, url || img.src, exifData, {
                width: img.width,
                height: img.height,
                originalWidth,
                originalHeight
              })
            } else {
              newWidth =
                resizeCount === 1 ? (img.width > img.height ? maxWidth : maxWidth / aspectRatio) : img.width / 2
              newHeight =
                resizeCount === 1 ? (img.width > img.height ? maxWidth / aspectRatio : maxWidth) : img.height / 2
              canvas.width = newWidth
              canvas.height = newHeight
              ctx.drawImage(img, 0, 0, newWidth, newHeight)
              // 获取压缩后的图像数据URL
              const compressedDataURL = canvas.toDataURL(outputFormat, quality)
              // 将数据URL转换为File对象
              const compressedFile = dataURLtoFile(compressedDataURL, initFile.name)
              resizeCount--
              compressStep(false, compressedFile, compressedDataURL)
            }
          }
        }
        // 找到最长边
        const maxImgLength = Math.max(img.width, img.height)
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        const aspectRatio = img.width > img.height ? maxImgLength / img.height : maxImgLength / img.width
        let newWidth = 0
        let newHeight = 0
        // 记录原始尺寸
        if (!originalWidth) {
          originalWidth = img.width
          originalHeight = img.height
        }
        // 记录缩放几次
        if (recordCount) {
          resizeCount = Math.floor(maxImgLength / maxWidth)
        }
        if (!exifData) {
          window.EXIF.getData(img, function () {
            exifData = window.EXIF.getAllTags(this)
            executeResize()
          })
          return
        }
        executeResize()
      }
      img.src = event.target.result as string
    }
    reader.readAsDataURL(file)
  }
  // 添加exif
  if (document.querySelector("#_exif")) {
    compressStep(true, initFile)
  } else {
    await asyncAppendScript("/js/exif.js", { id: "_exif" })
    compressStep(true, initFile)
  }
}

export function blobToImageSrc(blob: Blob) {
  const imageUrl = window.URL.createObjectURL(blob)
  return imageUrl
}

// blob url 转 file下载
export function blobToFile(url: string, fileName: string) {
  // const url = window.URL.createObjectURL(blob)
  // 创建一个<a>元素
  const a = document.createElement("a")
  a.href = url
  a.download = fileName // 设置下载的文件名
  // 将<a>元素添加到文档中并模拟点击
  document.body.appendChild(a)
  a.click()
  // 移除<a>元素
  document.body.removeChild(a)
  // 释放 Blob URL
  // URL.revokeObjectURL(url)
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve(reader.result as string)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(blob)
  })
}
// base64转file下载
export function base64ToFile(base64: string, fileName: string, mimeType = "image/jpg"): void {
  // 步骤 1: 解析 Base64 字符串
  // 去掉 Base64 字符串的头部部分（data:image/png;base64, 或其他类型）
  const base64Data = base64.split(",")[1]

  // 步骤 2: 解码 Base64 字符串为二进制数据
  const byteCharacters = atob(base64Data)

  // 步骤 3: 创建一个字节数组
  const byteArray = new Uint8Array(byteCharacters.length)

  // 步骤 4: 填充字节数组
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i)
  }

  // 步骤 5: 使用字节数组创建一个 Blob 对象
  const blob = new Blob([byteArray], { type: mimeType })

  // 步骤 6: 创建一个临时的 <a> 标签用于下载
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob) // 使用 Blob 对象的 URL
  link.download = fileName // 设置文件下载名称

  // 步骤 7: 触发下载
  link.click()

  // 步骤 8: 清理资源
  URL.revokeObjectURL(link.href) // 释放临时的 URL
}
// url下载图片
export async function downloadByUrl(imageUrl: string, fileName: string) {
  try {
    // 通过 fetch 获取图像数据
    const response = await fetch(imageUrl)

    if (!response.ok) {
      throw new Error("Failed to fetch image")
    }

    // 获取图片的二进制数据
    const imageBlob = await response.blob()

    // 创建一个 URL 对象
    const imageObjectUrl = URL.createObjectURL(imageBlob)

    // 创建一个 <a> 元素来模拟点击下载
    const link = document.createElement("a")
    link.href = imageObjectUrl
    link.download = fileName

    // 触发点击事件来启动下载
    link.click()

    // 释放 URL 对象
    URL.revokeObjectURL(imageObjectUrl)
  } catch (error) {
    console.error("Error downloading image:", error)
  }
}
// 计算文件md5
export async function calculateFileMD5(file) {
  return new Promise((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer()
    const reader = new FileReader()

    reader.onload = function (event) {
      spark.append(event.target.result)
      resolve(spark.end()) // 获取文件的 MD5 值
    }

    reader.onerror = function (error) {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}
// 将数据URL转换为File对象的函数
export function dataURLtoFile(dataURL, fileName) {
  const arr = dataURL.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mime })
}
// 获取图片分辨率
export function getImageResolution(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    if (!file || !file.type.startsWith("image/")) {
      return reject(new Error("The file is not an image."))
    }
    const img = new Image()
    // 使用 Object URL 代替 FileReader 读取文件
    const url = URL.createObjectURL(file)
    img.onload = () => {
      // 获取图片分辨率
      const resolution = { width: img.width, height: img.height }
      // 释放 Object URL 以避免内存泄漏
      URL.revokeObjectURL(url)
      // 返回分辨率
      resolve(resolution)
    }
    img.onerror = () => {
      // 图片加载失败，释放资源
      URL.revokeObjectURL(url)
      reject(new Error("Failed to load the image."))
    }
    // 设置图片源为 Object URL
    img.src = url
  })
}
export class UploadAjaxError extends Error {
  name = "UploadAjaxError"
  status: number
  method: string
  url: string

  constructor(message: string, status: number, method: string, url: string) {
    super(message)
    this.status = status
    this.method = method
    this.url = url
  }
}

export interface UploadRawFile extends File {
  uid: number
}

export interface UploadProgressEvent extends ProgressEvent {
  percent: number
}

export interface UploadRequestOptions {
  action: string
  method: string
  data: Record<string, string | Blob | [string | Blob, string]>
  filename: string
  file: UploadRawFile
  headers: Headers | Record<string, string | number | null | undefined>
  onError: (evt: UploadAjaxError) => void
  onProgress: (evt: UploadProgressEvent) => void
  onSuccess: (response: any) => void
  withCredentials: boolean
}

export type UploadRequestHandler = (options: UploadRequestOptions) => XMLHttpRequest | Promise<unknown>

function getError(action: string, option: UploadRequestOptions, xhr: XMLHttpRequest) {
  let msg: string
  if (xhr.response) {
    try {
      msg = JSON.parse(xhr.response).message
    } catch (e) {
      msg = xhr.response
    }
  } else if (xhr.statusText) {
    msg = `${xhr.statusText}`
  } else {
    msg = `fail to ${option.method} ${action} ${xhr.status}`
  }

  return new UploadAjaxError(msg, xhr.status, option.method, action)
}

function getBody(xhr: XMLHttpRequest): XMLHttpRequestResponseType {
  if (xhr.response) {
    return xhr.response
  }
  const text = xhr.responseText || xhr.response
  console.log(xhr)
  if (!text) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch {
    return text
  }
}

export const ajaxUpload: UploadRequestHandler = (option) => {
  if (typeof XMLHttpRequest === "undefined") console.error("XMLHttpRequest is undefined")

  const xhr = new XMLHttpRequest()

  xhr.responseType = "blob"

  const action = option.action

  if (xhr.upload) {
    xhr.upload.addEventListener(
      "progress",
      (evt) => {
        const progressEvt = evt as UploadProgressEvent
        progressEvt.percent = evt.total > 0 ? (evt.loaded / evt.total) * 100 : 0
        option.onProgress(progressEvt)
      },
      { passive: true }
    )
  }

  const formData = new FormData()
  if (option.data) {
    for (const [key, value] of Object.entries(option.data)) {
      if (isArray(value) && value.length) formData.append(key, ...value)
      else formData.append(key, value)
    }
  }
  formData.append("file", option.file)

  xhr.addEventListener(
    "error",
    () => {
      option.onError(getError(action, option, xhr))
    },
    { passive: true }
  )

  xhr.addEventListener(
    "load",
    () => {
      if (xhr.status < 200 || xhr.status >= 300) {
        return option.onError(getError(action, option, xhr))
      }
      option.onSuccess(getBody(xhr))
    },
    { passive: true }
  )

  xhr.open(option.method, action, true)

  if (option.withCredentials && "withCredentials" in xhr) {
    xhr.withCredentials = true
  }

  const headers = option.headers || {}
  if (headers instanceof Headers) {
    headers.forEach((value, key) => xhr.setRequestHeader(key, value))
  } else {
    for (const [key, value] of Object.entries(headers)) {
      if (isNil(value)) continue
      xhr.setRequestHeader(key, String(value))
    }
  }

  xhr.send(formData)
  return xhr
}
