// 路由/c/ /s/的所对应的落地页字典
import {
  LANDING_COMBO_PAGE,
  LANDING_KOL_PAGE,
  LANDING_PAGE_PATH,
  LANDING_SIMPLE_PAGE,
  LANDING_KOL_S_PAGE,
  LANDING_COMBO_MUL,
  LANDING_US,
  LANDING_S_US,
  LANDING_D,
  LADING_JP,
  LANDING_COMBO_MUL_LN,
  LANDING_K_S_LN,
  LANDING_COMBO_MUL_XS,
  LANDING_COMBO_MUL_T_XS,
  LANDING_PROMOTION,
  LANDING_GENERIC,
  LANDING_FREE_TRIAL,
  LANDING_PAGE_MS,
  LANDING_PAGE_YS,
  LANDING_TRADE_SHOW
} from "@/constant/route"

// //   /c/ 活动短路由下的活动
// export const shotActivityLandingList = [
//   LANDING_COMBO_PAGE,
//   LANDING_KOL_PAGE,
//   LANDING_PAGE_PATH,
//   LANDING_SIMPLE_PAGE,
//   LANDING_KOL_S_PAGE,
//   LANDING_COMBO_MUL,
//   LANDING_US,
//   LANDING_S_US,
//   LADING_JP,
//   LANDING_COMBO_MUL_LN,
//   LANDING_K_S_LN,
//   LANDING_COMBO_MUL_XS,
//   LANDING_COMBO_MUL_T_XS,
//   LANDING_PROMOTION
// ]

// // /s/ 渠道短路由下的活动
// export const shotChannelLandingList = [
//   LANDING_PAGE_PATH,
//   LANDING_SIMPLE_PAGE,
//   LANDING_KOL_PAGE,
//   LANDING_D,
//   LANDING_KOL_S_PAGE,
//   LANDING_US,
//   LANDING_S_US,
//   LADING_JP,
//   LANDING_K_S_LN,
//   LANDING_GENERIC
// ]

// 对应的path落地页组件字典
export const showLandingDict = () => {
  return {
    [unref(LANDING_COMBO_PAGE)]: {
      com: "landing_combo",
      layout: "landing"
    },
    [unref(LANDING_COMBO_MUL)]: {
      com: "landing_combo_mul",
      layout: "landing"
    },
    [unref(LANDING_KOL_PAGE)]: {
      // landing_k跳转landing_k_s
      com: "landing_k_s",
      layout: "landing"
    },
    [unref(LANDING_PAGE_PATH)]: {
      com: "landing",
      layout: "landing"
    },
    [unref(LANDING_KOL_S_PAGE)]: {
      com: "landing_k_s",
      layout: "landing"
    },
    [unref(LANDING_SIMPLE_PAGE)]: {
      com: "landing_s",
      layout: "landing"
    },
    [unref(LANDING_D)]: {
      com: "landing_d",
      layout: "default"
    },
    [unref(LANDING_US)]: {
      com: "landing_us",
      layout: "landing"
    },
    [unref(LANDING_S_US)]: {
      com: "landing_s_us",
      layout: "landing"
    },
    [unref(LADING_JP)]: {
      com: "landing_jp",
      layout: "landing"
    },
    [unref(LANDING_COMBO_MUL_LN)]: {
      com: "landing_combo_mul_ln",
      layout: "no-font-landing"
    },
    [unref(LANDING_K_S_LN)]: {
      com: "landing_k_s_ln",
      layout: "no-font-landing"
    },
    [unref(LANDING_COMBO_MUL_XS)]: {
      com: "landing_combo_mul_xs",
      layout: "landing"
    },
    [unref(LANDING_COMBO_MUL_T_XS)]: {
      com: "landing_combo_mul_t_xs",
      layout: "landing"
    },
    [unref(LANDING_COMBO_MUL_T_XS)]: {
      com: "landing_combo_mul_t_xs",
      layout: "landing"
    },
    [unref(LANDING_PROMOTION)]: {
      com: "landing_promotion",
      layout: "landing"
    },
    [unref(LANDING_GENERIC)]: {
      com: "landing_generic",
      layout: "landing"
    },
    [unref(LANDING_FREE_TRIAL)]: {
      com: "landing_free_trial",
      layout: "landing"
    },
    [unref(LANDING_PAGE_MS)]: {
      com: "landing_ms",
      layout: "landing"
    },
    [unref(LANDING_PAGE_YS)]: {
      com: "landing_ys",
      layout: "landing"
    },
    [unref(LANDING_TRADE_SHOW)]: {
      com: "landing_tradeshow",
      layout: "no-font-landing"
    }
  }
}
