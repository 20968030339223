import { EmitName } from "@/constant/eventBus"

export const useEventBusOn = (name: EmitName, fn: (...args: any[]) => any) => {
  const { $mitt: mitt } = useNuxtApp()
  mitt.on(name, fn)
}

export const useEventBusEmit = (name: EmitName, args?: any) => {
  const { $mitt: mitt } = useNuxtApp()
  mitt.emit(name, args)
}

export const useEventBusOff = (name: EmitName, fn: (...args: any[]) => any) => {
  const { $mitt: mitt } = useNuxtApp()
  mitt.off(name, fn)
}

export const useEventBusClear = () => {
  const { $mitt: mitt } = useNuxtApp()
  mitt.all.clear()
}
