<template>
  <teleport to="body">
    <div v-if="show" class="common-loading-wrapper">
      <div class="common-loading-wrapper-snipper">
        <div class="img">
          <CommonImage :src="src" />
        </div>
        <span class="loading-text">{{ useTranslateI18n("common.loading.t1") }}</span>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
interface IProps {
  show: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  show() {
    return false
  }
})

let src = getCloudAssets("/images/common/loading-icon.png")
</script>

<style scoped lang="scss">
@keyframes rotate-icon {
  100% {
    transform: rotate(360deg);
  }
}
.common-loading-wrapper {
  position: fixed;
  z-index: 9999;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  &-snipper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img {
      width: 80px;
      height: 80px;
      animation: rotate-icon 1s linear infinite;
    }
    .loading-text {
      @include fontRobotoBold;
      font-size: 24px;
      margin-top: 20px;
    }
  }
}
</style>
