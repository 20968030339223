<!-- 支付状态弹窗 -->
<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="payment-result_dialog"
    :dialog-style="dialogStyle"
    :show-close-icon="showCloseIcon"
  >
    <div class="payment-result-container">
      <PaySuccess v-if="payResult === IPaymentResultType.success" />
      <PayFail v-else />
      <p class="title">
        {{
          payResult === IPaymentResultType.success
            ? useTranslateI18n("webEditor.payment.t9")
            : useTranslateI18n("webEditor.payment.t13")
        }}
      </p>
      <p v-if="payResult === IPaymentResultType.success" class="desc desc-account">
        {{ useTranslateI18n("webEditor.payment.t10", [userEmail]) }}
      </p>
      <p class="desc">
        {{
          payResult === IPaymentResultType.success
            ? useTranslateI18n("webEditor.payment.t11")
            : useTranslateI18n("webEditor.payment.t14")
        }}
      </p>

      <CommonButton :button-style="buttonStyle" @btn-click="submitHandler"
        ><CommonAnimationScrollText
          >{{
            payResult === IPaymentResultType.success
              ? hasExportImage
                ? useTranslateI18n("webEditor.payment.t12")
                : useTranslateI18n("webEditor.payment.t16")
              : useTranslateI18n("webEditor.payment.t15")
          }}
          <span v-if="closeTimeout">{{ "(" + remainingTime + "s" + ")" }}</span>
        </CommonAnimationScrollText></CommonButton
      >
    </div>
    <CommonLoading :show="showLoading" />
  </CommonDialog>
</template>

<script setup lang="tsx">
import { IPaymentResultType } from "@/types/components/pages/payment/successDetail"
import { LocalStorageKey } from "@/constant/cache"
import { useUser } from "~/store/user"
import PaySuccess from "@/assets/icons/pages/newFeature/pay_success.svg"
import PayFail from "@/assets/icons/pages/newFeature/pay_fail.svg"
interface IPaymentResult {
  type: IPaymentResultType
  name?: string
  currency?: string
  value?: number
  showMoreInfo?: boolean
}
const props = defineProps({
  closeDialog: {
    type: Function,
    default() {
      return () => {}
    }
  },
  success: {
    type: Function,
    default() {
      return () => {}
    }
  },
  showCloseIcon: {
    type: Boolean,
    default() {
      return false
    }
  },
  closeTimeout: {
    type: Number,
    default() {
      return 1000 * 5
    }
  },
  hasExportImage: {
    type: Boolean,
    default() {
      return false
    }
  }
})
const dialogStyle = reactive({
  backgroundColor: "#fff"
})
const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})
const showDialog = ref(true)
let showLoading = ref(true)

const openLoading = () => {
  showLoading.value = true
}

const closeLoading = () => {
  showLoading.value = false
}
const payResult = ref<IPaymentResultType>()
const use_user = useUser()
const { userEmail } = use_user
const submitHandler = async () => {
  showDialog.value = false
  if (timeout) {
    clearTimeout(timeout)
    clearInterval(interval)
  }
  if (payResult.value === IPaymentResultType.success && props.hasExportImage) {
    useDownloadHdDialog()
  } else if (payResult.value === IPaymentResultType.error) {
    // 返回到支付页面
    useTrialPackageDialog()
  }
}
// 初始化链接参数
const { type, currency, value, transaction_id, order_id } = useRoute().query
let _transaction_id = transaction_id
  ? Array.isArray(transaction_id)
    ? decodeURIComponent(transaction_id[0])
    : decodeURIComponent(transaction_id)
  : undefined
let _order_id = order_id
  ? Array.isArray(order_id)
    ? decodeURIComponent(order_id[0])
    : decodeURIComponent(order_id)
  : undefined
let _currency = Array.isArray(currency) ? currency[0] : currency
let _value = Array.isArray(value) ? value[0] : value
let _type = (Array.isArray(type) ? type[0] : type) as IPaymentResultType
const getPaymentResult = async (data?: IPaymentResult) => {
  // passion
  if (data) {
    setSuccessPaymentPointer({ currency: data.currency, value: data.value, type: data.type })
  } else {
    setSuccessPaymentPointer()
  }

  const { type } = data || {}
  payResult.value = type || _type
  setCloseTimeout() // 倒计时自动关闭
  if (payResult.value === IPaymentResultType.success) {
    // 支付成功 更新张数
    await use_user.setAllUserInfo()
  }
}
const setSuccessPaymentPointer = (data?: Pick<IPaymentResult, "currency" | "value" | "type">) => {
  let resultValue = data?.value || (_value ? Number(_value) : undefined)
  let resultCurrency = data?.currency || (_currency ? _currency.toUpperCase() : undefined)
  let resultType = data?.type || _type
  // 支付成功埋点
  if ((resultType as IPaymentResultType) === "success") {
    if (process.client) {
      useAdsPurchasePoint(resultValue, resultCurrency as any)
      getLocalStorage(LocalStorageKey.AllowCookiesPrivacy)
        ? useSelfPointer(`/__static__/enter_payment_w_cookie.txt`)
        : useSelfPointer(`/__static__/enter_payment.txt`)
    }
  }
}
let detectionCount = 60
let detectionTimeout = 1000
let detectionCountIndex = 1
// 检测是否支付成功
async function detectionOrderStatus() {
  // 检测passion订单状态
  openLoading()

  // 轮询60次直接跳转失败页面
  if (detectionCountIndex > detectionCount) {
    closeLoading()
    // 跳转失败页面
    getPaymentResult({
      type: IPaymentResultType.error,
      showMoreInfo: false
    })
    return
  }

  try {
    // 获取查询方法：passion airwallex
    const { trade_state, product_name, amount, currency } = await postPaymentPolyQuery({
      trade_no: _order_id as string,
      transaction_id: _transaction_id as string
    })

    let tId

    switch (trade_state) {
      case ITradeState.Success:
        // 支付成功套餐金额埋点 ads 埋点
        getPaymentResult({
          type: IPaymentResultType.success,
          name: product_name,
          currency: currency.toUpperCase(),
          value: Number(amount) as any,
          showMoreInfo: false
        })

        closeLoading()
        break
      case ITradeState.Close:
        getPaymentResult({
          type: IPaymentResultType.error,
          showMoreInfo: false
        })
        closeLoading()
        break
      // 继续请求结果
      case ITradeState.Pending:
        tId = setTimeout(() => {
          detectionCountIndex++
          detectionOrderStatus()
          clearTimeout(tId)
        }, detectionTimeout)
        break
    }
  } catch (e) {
    closeLoading()
  }
}
const remainingTime = ref(props.closeTimeout / 1000)
let interval
let timeout
const setCloseTimeout = () => {
  // 倒计时自动关闭
  if (props.closeTimeout) {
    interval = setInterval(() => {
      if (remainingTime.value > 0) {
        remainingTime.value--
      } else {
        clearInterval(interval)
      }
    }, 1000)
    const timer = Number(props.closeTimeout) || 0
    timeout = setTimeout(() => {
      submitHandler()
    }, timer)
  }
}
onMounted(async () => {
  // 本地支付结果
  if (_transaction_id || _order_id) {
    detectionOrderStatus()
    return
  }
  openLoading()
  getPaymentResult()
  closeLoading()
})

onBeforeUnmount(() => {
  detectionCountIndex = 1000
  closeLoading()
})
</script>

<style lang="scss" scoped>
.payment-result-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //   gap: 16px;
  .title {
    color: #000;
    text-align: center;
    font-family: Roboto-Bold;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin: 16px 0px;
  }
  .desc {
    color: rgba(0, 0, 0, 0.55);
    font-family: Roboto-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    max-width: 372px;
    margin-bottom: 40px;
    &-account {
      margin-bottom: 8px;
    }
  }
}
</style>
