<template>
  <div class="login-with-code-wrapper">
    <div class="top-title">
      <span class="t-1">{{ useTranslateI18n("login.newLogin.t2") }}</span>
      <span
        v-if="showPsdChange"
        class="t-2"
        v-html="useTranslateI18n('login.newLogin.t3', [{ type: 'static', class: 't-2-l' }])"
      ></span>
    </div>
    <div class="desc">
      <span class="t-1" v-html="topTitle"> </span>
    </div>
    <CommonVerCodeForm2 ref="verCodeRef" @enter-click="logInClick" />
    <Transition name="fade">
      <span v-show="invalidCodeShow" class="err-code">{{ useTranslateI18n("login.email.invalid") }}</span>
    </Transition>
    <div :class="['resend-code']">
      <span v-show="isTiming" :class="[isTiming ? 'resend-code-active' : '']">
        {{ useTranslateI18n("login.email.resend") }}&nbsp;({{ count }})</span
      >

      <span v-show="!isTiming" @click="resendCodeEvent"> {{ useTranslateI18n("login.email.resend") }}</span>
    </div>
    <div ref="loadingRef" class="submit-button-w">
      <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="logInClick"
        ><CommonAnimationScrollText>{{
          useTranslateI18n("login.newLogin.t4")
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { LOGIN_SCENE, IErrorLoginType } from "@/constant/login"
import { useUser } from "@/store/user"
import { SessStorageKey } from "~~/constant/cache"

interface IProps {
  email: string
  showPsdChange: boolean
  phone: {
    countryValue: string
    inputValue: string
  }
  scene: LOGIN_SCENE
  closeDialog?: () => void
}

const props = withDefaults(defineProps<IProps>(), {
  email() {
    return ""
  },
  showPsdChange() {
    return true
  },
  phone() {
    return undefined
  },
  scene() {
    return LOGIN_SCENE.CODE_LOGIN
  },
  closeDialog() {
    return () => {}
  }
})
// 判断选择方式 email or phone
let enterEmail = ref(!unref(props.phone))
const emits = defineEmits(["goBack", "goEnterPsd"])

const { _pointLoginAndRegFlowPageButtonView } = useCommonMp()
const { _pointLogInWithCodeClick } = useStSdkCommonMp()

const topTitle = computed(() => {
  return props.email
    ? useTranslateI18n("login.newLogin.t11", [
        { text: props.email, class: "t-1-a" },
        { type: "static", class: "t-1-l" }
      ])
    : useTranslateI18n("login.newLogin.t22", [
        { text: `+${props.phone?.countryValue} ${props.phone?.inputValue}`, class: "t-1-a" },
        { type: "static", class: "t-1-l" }
      ])
})

let account = computed(() => {
  return unref(props.phone) ? `+${unref(props.phone)?.countryValue} ${unref(props.phone)?.inputValue}` : props.email
})

let use_user = useUser()

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const { isTiming, timekeeping, count, verCodeRef, resendCode, invalidCodeShow, sendCodeApi } =
  useVerCodeCountDown("login-with-code-wrapper")
const resendCodeEvent = () => {
  _pointLogInWithCodeClick({
    element_name: "resend_code",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "login",
    feature_name: "login_with_code"
  })
  resendCode()
}
const goBack = useDebounceFn(() => {
  _pointLogInWithCodeClick({
    element_name: unref(enterEmail) ? "change_email_address" : "change_phone_number",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "login",
    feature_name: "login_with_code"
  })
  _pointLoginAndRegFlowPageButtonView({
    featureName: "login",
    elementName: "change_account",
    pageTitle: "login_with_code"
  })
  emits("goBack")
}, 300)

const goEnter = useDebounceFn(() => {
  emits("goEnterPsd")
}, 300)

let enterPsdEvent
let goBackEvent
onMounted(() => {
  enterPsdEvent = useEventListener(
    document.querySelector(".login-with-code-wrapper .top-title .t-2 .t-2-l"),
    "click",
    goEnter
  )

  goBackEvent = useEventListener(document.querySelector(".login-with-code-wrapper .desc .t-1 .t-1-l"), "click", goBack)

  // 发送验证码
  setTimeout(() => {
    if (!isTiming.value) {
      sendCodeApi()
    }
  })
})

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const { getSSORedirectUrl } = useSSO()
const ssoRedirectUrl = getSSORedirectUrl()

const logInClick = async () => {
  _pointLogInWithCodeClick({
    element_name: "login",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "login",
    feature_name: "login_with_code"
  })
  _pointLoginAndRegFlowPageButtonView({
    featureName: "login",
    elementName: "login_with_code",
    pageTitle: "login_with_code"
  })
  if (verCodeRef.value) {
    let verCodeArr = verCodeRef.value.resultCode
    if (
      !verCodeArr.every((item) => {
        return item.length
      })
    ) {
      invalidCodeShow.value = true
    } else {
      if (unref(isClick)) return
      openLoading()
      invalidCodeShow.value = false
      let code = useMd5(verCodeArr.join(""))
      try {
        const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
        let data = await setStateLoginApi({
          email: props.email || undefined,
          phone: props.phone?.inputValue,
          phone_cc: props.phone?.countryValue,
          verify_code: code,
          no_passwd: 1,
          ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
        })
        await use_user.setUserInfo({
          info: data.info.user_info,
          token: data.token,
          redirectUrl: ssoRedirectUrl
        })
      } catch (e) {
        invalidCodeShow.value = true
        // 判断用户是否开启二次认证了，如果是二次认证的话
        if (e?.data?.code === IErrorLoginType.RepeatVerify) {
          const { showRepeatAuthDialog } = useUserAuthRepeatDialog()
          showRepeatAuthDialog(
            {
              email: props.email,
              phone: props.phone
            },
            true,
            ssoRedirectUrl
          )
        }
        if (e?.data?.code === IErrorLoginType.Retrieve) {
          const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
          showRetrieveDialog(unref(account), e?.data?.data?.recover_token, true, ssoRedirectUrl)
        }
      } finally {
        closeLoading()
        // 未刷新页面时 手动关闭弹窗
        props.closeDialog && props.closeDialog()
        // if (useNoReflushLogin()) {
        //   props.closeDialog()
        // }
      }
    }
  }
}

onBeforeUnmount(() => {
  enterPsdEvent && enterPsdEvent()
  goBackEvent && goBackEvent()
})
</script>

<style scoped lang="scss">
.login-with-code-wrapper {
  display: flex;
  flex-direction: column;
  .top-title {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    display: flex;
    flex-direction: column;
    .t-2 {
      color: rgba(0, 0, 0, 0.55);
      font-size: 16px;
      line-height: 150%; /* 24px */
      margin-top: 8px;
      @include fontRegular;
      :deep(.t-2-l) {
        position: relative;
        line-height: 150%;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .desc {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
    margin-top: 48px;
    margin-bottom: 29px;
    :deep(.t-1-l) {
      color: $bg-color-link;
      cursor: pointer;
      text-decoration: underline;
    }
    :deep(.t-1-a) {
      text-decoration: underline;
    }
  }

  .resend-code {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-style: normal;
    display: flex;
    cursor: pointer;
    .resend-code-active {
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      padding: 4px 8px;
      border-radius: 42px;
      background: rgba(0, 0, 0, 0.05);
      cursor: not-allowed;
    }
  }
  .err-code {
    margin-top: 8px;
    font-weight: 400;
    line-height: 17px;
    color: #fc5555;
  }
  .submit-button-w {
    margin-top: 40px;
  }
}
</style>
