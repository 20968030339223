import { ReferralApi } from "~/constant/api/referral"
import http2 from "~/http/http2"
import http from "@/http/index"

export type IReferralPageData = {
  has_name_set: boolean
  state: IReferralPageState
  reward: IReferralPageDataReward
  top_user: IReferralPageDataTopUser
}

export type IReferralPageDataReward = {
  invitees: {
    credits: number
    reward_credits: number
  }[] // 列
  inviter_credits: number
}[]

export enum IReferralPageState {
  Normal = 0, // 正常
  NoSub, // 未订阅
  BigTrade, //  75K 大套餐
  Refunded, // 退款N次 冻结
  Forbidden // 手动冻结
}

export type IReferralPostName = {
  first_name: string
  last_name: string
}

export type IReferralConfig = {
  landing_link: string
  social_media: ICommonGroupLink[]
}

export type IReferralCreditsOptions = {
  code: string
  product_id: number
}

export type IReferralPageDataTopUser = { avatar: string; credits: number; name: string }[]

export type IReferralInviterLink = { code: string; link: string; has_name_set: boolean }

export const getReferralData = <T = IReferralPageData>(isClient?: boolean) => {
  return (isClient ? http : http2).get<T>(ReferralApi.GetReferralData)
}

export const getReferralLink = <T = IReferralInviterLink>() => {
  return http2.get<T>(ReferralApi.GetReferralLink)
}

export const postReferralName = <T = any>(options: IReferralPostName) => {
  return http2.post<T>(ReferralApi.PostReferralName, options)
}

export const getReferralWebsiteConfig = <T = IReferralConfig>() => {
  return http2.get<T>(ReferralApi.GetReferralConfig)
}

export const postReferralCredits = <T = { credits: number }>(options: IReferralCreditsOptions) => {
  return http2.post<T>(ReferralApi.PostReferralCredits, options)
}
