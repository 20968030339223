import { getCloudAssets } from "~~/utils"
export const paymentSubPageConfig = () => {
  return {
    newSub: {
      com: `newSub`,
      layout: "default"
    },
    sub: {
      com: "sub",
      layout: "default"
    },
    blackFriday: {
      com: "blackFriday",
      layout: "default"
    }
  }
}

export const paymentComboPageConfig = () => {
  return {
    combo: {
      com: `combo`,
      layout: "default"
    },
    blackFriday: {
      com: "blackFriday",
      layout: "default"
    }
  }
}

export enum IErrorPaymentType {
  VerCouponCode = 5053
}

// 本地saved支付的字典
export const getPaymentSavedLocalDict = (key: INewMyBillingDataPaymentMethodName) => {
  const dict = new Map<INewMyBillingDataPaymentMethodName, { iconSrc: string; label: string; bg: string }>([
    [
      INewMyBillingDataPaymentMethodName.kakaopay_kr,
      {
        label: useTranslateI18n("payment.comboSub.t101", ["Kakao Pay"]),
        bg: "#F8F8F2",
        iconSrc: getCloudAssets(`/images/common/pay-icons/kakao_pay_local.png`)
      }
    ]
  ])

  return dict.get(key)
}
