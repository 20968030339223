import { useOriginPath } from "~~/constant/useLocalPathConfig"

export const useNoRrefreshCallbackStateRoute = () => useState<string>("noRefreshCallbackStateRoute", () => "")

export const useNoRefreshCallbackStateRouteMixinFn = () => {
  const setNoRefreshCallbackStateRoute = (path?: string) => {
    useNoRrefreshCallbackStateRoute().value = useOriginPath(path || useRoute().path)
  }

  const getNoRefreshCallbackStateRoute = () => {
    return useNoRrefreshCallbackStateRoute().value
  }

  const isCallbackStateRoute = () => {
    const { path } = useRoute()
    return getNoRefreshCallbackStateRoute() === useOriginPath(path)
  }

  const watchLoginCallback = (callback: () => void | Promise<void>) => {
    const un = watch(
      () => useNoRrefreshCallbackStateRoute().value,
      () => {
        if (isCallbackStateRoute()) {
          const { openDialog, closeDialog } = useCommonGlobalLoading()
          openDialog()
          const fn = callback()
          if (fn instanceof Promise) {
            fn.finally(() => {
              closeDialog()
            })
          } else {
            closeDialog()
          }
        }
        setNoRefreshCallbackStateRoute("")
        un && un()
      }
    )
  }

  return {
    setNoRefreshCallbackStateRoute,
    getNoRefreshCallbackStateRoute,
    isCallbackStateRoute,
    watchLoginCallback
  }
}
