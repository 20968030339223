import { createVNode, render, CSSProperties } from "vue"
import FeedbackDialog from "~~/components/pages/newFeature/Feedback/Dialog.vue"
// import TrialPackageDialog from "~~/components/pages/newFeature/common/TrialPackageDialog.vue"
import DownloadHdDialog from "~~/components/pages/newFeature/common/DownloadImgDialog.vue"
import PaymentResultDialog from "~~/components/pages/newFeature/common/PaymentResultDialog.vue"

type DialogParams = {
  zIndex?: number
  popperClass?: string
  width?: number
  headTitle?: string
  headDesc?: string
  contentDesc?: string
  submitBtnText?: string
  cancelBtnText?: string
  submitCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  cancelCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  btnDisabled?: { submit: boolean; cancel: boolean }
  contentSlots?: ({ close }: { close: () => any }) => VNode
  showCloseIcon?: boolean | Ref<boolean>
  close?: (...args: any[]) => any
  loadSuccess?: (...args: any[]) => any
  dialogWrapperStyle?: CSSProperties
  fromType?: string
  closeTimeout?: number
}
type FeedbackParams = DialogParams & {
  image?: Record<string, any>
}
type ImageDialogParams = DialogParams & {
  image?: {
    fileName: string
    type: string
    url: string
    effectUrl: string
    md5: string
    resolution: {
      width: number
      height: number
      originalWidth: number
      originalHeight: number
    }
    [key: string]: any
  }
}
type PayResultParams = DialogParams & {
  callbackType?: string
  hasExportImage?: boolean
}
// 试用套餐
export function useTrialPackageDialog(options: DialogParams = {}) {
  const TrialPackageDialog = defineAsyncComponent(
    () => import("~~/components/pages/newFeature/common/TrialPackageDialog.vue") // git上 common是小写
  )
  const vm = createVNode(TrialPackageDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
// 下载高清弹窗
export function useDownloadHdDialog(options: ImageDialogParams = {}) {
  // const DownloadHdDialog = defineAsyncComponent(
  //   () => import("~~/components/pages/newFeature/common/DownloadImgDialog.vue")
  // )
  const vm = createVNode(DownloadHdDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
// 反馈弹窗
export function useFeedbackDialog(options: FeedbackParams = {}) {
  // const FeedbackDialog = defineAsyncComponent(() => import("~~/components/pages/newFeature/Feedback/Dialog.vue"))
  const vm = createVNode(FeedbackDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
// 支付状态弹窗
export function usePaymentResultDialog(options: PayResultParams = {}) {
  // const PaymentResultDialog = defineAsyncComponent(
  //   () => import("~~/components/pages/newFeature/common/PaymentResultDialog.vue")
  // )
  const vm = createVNode(PaymentResultDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
