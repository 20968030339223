import { toDownloadQueryDict, directDownloadFeature, translatedFeature, webEditorPage } from "~~/constant/newFeature"
import { useOriginPath } from "~~/constant/useLocalPathConfig"
import { RouteLocationNormalized } from "vue-router"
import { ILocaleValue } from "~/constant/i18n-country"
import { SessStorageKey } from "@/constant/cache"
import { SignFromType } from "@/constant/login"

// 各feature跳下载的方法(带渠道)
export const useFeatureToDownload = (open = true) => {
  // 跳下载
  const route = useRoute()
  const { path, query = {} } = route
  const lastPath = useOriginPath(path)
  const utm_query = toDownloadQueryDict[lastPath] || ""
  // 序列化对象
  const _query = utm_query
    ?.split("&")
    .map((item) => item.split("="))
    .reduce((prev, cur) => {
      prev[cur[0]] = cur[1]
      return prev
    }, {})
  // 优先使用query参数
  const { sId = "", _uuid = "" } = query
  sId && (_query.sId = sId)
  _uuid && (_query._uuid = _uuid)
  const url_query = new URLSearchParams(_query).toString()
  const url = useLocalePath()("/download") + "?" + url_query
  if (open) {
    window.open(url, "_blank")
  }
  return url
}
// 直接下载
export const useFeatureDirectDownload = async () => {
  const route = useRoute()
  const { path, fullPath, query = {} } = route
  const lastPath = useOriginPath(path)
  const utm_query = toDownloadQueryDict[lastPath] || ""
  // 序列化对象
  const _query = utm_query
    ?.split("&")
    .map((item) => item.split("="))
    .reduce((prev, cur) => {
      prev[cur[0]] = cur[1]
      return prev
    }, {})
  // 白名单跳下载页
  if (!directDownloadFeature.includes(lastPath)) {
    useFeatureToDownload()
    return
  }
  // 部分直接下载
  const mockRouter = {
    path,
    fullPath,
    query: {
      ..._query,
      ...query
    }
  } as RouteLocationNormalized
  // 处理渠道参数
  await useSidParams(mockRouter)
  const { download } = useDownload()
  const { deviceType } = useDevice()
  download(deviceType.value)
}
// 设置feature页渠道参数
export const useFeatureSidParams = async () => {
  const route = useRoute()
  const { path, fullPath, query = {} } = route
  const lastPath = useOriginPath(path)
  const utm_query = toDownloadQueryDict[lastPath] || ""
  // 序列化对象
  const _query = utm_query
    ?.split("&")
    .map((item) => item.split("="))
    .reduce((prev, cur) => {
      prev[cur[0]] = cur[1]
      return prev
    }, {})
  const mockRouter = {
    path,
    fullPath,
    query: {
      ..._query,
      ...query
    }
  } as RouteLocationNormalized
  // 处理渠道参数
  await useSidParams(mockRouter)
}

// 是否有翻译
export const hasFeatureTranslate = () => {
  const path = useOriginPath(useRoute().path)
  const language = useI18nCountry()
  const hasTranslatedFeature = translatedFeature()
  const translatedPath = Object.keys(hasTranslatedFeature)
  return translatedPath.includes(path) && hasTranslatedFeature[path].includes(language.value as ILocaleValue)
}

// 博客链接对应多语言
export const blogI18nURL = (url) => {
  const locale = useI18nCountry().value as ILocaleValue
  const blog = "blog.evoto.ai"
  if (url.toString().includes(blog) && [ILocaleValue.de, ILocaleValue.fr].includes(locale)) {
    const path = url.split(blog)
    return path[0] + `${blog}/${locale}` + path[1]
  } else {
    return url
  }
}
//
export const isFromWebEditor = (): boolean => {
  return (
    sessionStorage.getItem(SessStorageKey.SIGN_FROM_TYPE) === SignFromType.Buy ||
    sessionStorage.getItem(SessStorageKey.SIGN_FROM_TYPE) === SignFromType.Export
  )
}

// 是否是web editor页面
export const isWebEditorPage = () => {
  const path = useOriginPath(useRoute().path)
  return webEditorPage.includes(path)
}
