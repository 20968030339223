import { TOKEN_ROUTER } from "@/constant/permission/index"
import { useUser } from "@/store/user"
import { TOKEN_KEY } from "@/constant/cookies"
import { LocalStorageKey } from "@/constant/cache"
import { getHeadLanguage } from "@/constant/i18n-country"
import { IErrorLoginType } from "@/constant/login"
import { IErrorType } from "@/constant/newFeature"
import { stHeaderEncodeURI } from "@/composables/useTsSdk"
import { IErrorPaymentType } from "@/constant/payment"
// import type { UseFetchOptions } from "nuxt/dist/app/composables"
import type { FetchOptions } from "ohmyfetch"

import type { ResponseConfig } from "./interface"
import { enumErrorMessage, codePos, messagePos, TIMEOUT_TIME, commonErrorCode } from "./enum"

const noMessageTipDict = [
  IErrorLoginType.RepeatVerify,
  IErrorLoginType.Retrieve,
  IErrorLoginType.RedeemCode1,
  IErrorLoginType.RedeemCode2,
  IErrorLoginType.VerCode,
  IErrorType.unFormatCode,
  IErrorType.noFaceCode,
  IErrorPaymentType.VerCouponCode
]

const baseUrl = process.env.BASE_URL || "/"

function isOnline() {
  if (process.client) {
    return window.navigator ? window.navigator.onLine : true
  }
}

export function getToken() {
  const token = useCookie(TOKEN_KEY)
  return token.value || ""
}

export function resetToken() {
  const token = useCookie(TOKEN_KEY)
  token.value = null
}

// 处理错误信息
export function handlerError(reject, err) {
  const use_user = useUser()
  if (process.server) {
    if (err.statusCode == 401) {
      // resetToken()
    }
    return reject(err)
  }
  if (!isOnline()) {
    useErrorMessage(enumErrorMessage.NET_WORK_MESSAGE)
    err.message = enumErrorMessage.NET_WORK_MESSAGE
    return reject(err)
  }
  const code = useGet(err, codePos, useGet(err, "response.status", useGet(err, "statusCode", -1)))
  const message = useGet(
    err,
    messagePos,
    useGet(err, "response.statusText", useGet(err, "message", enumErrorMessage.UNKNOWN_MESSAGE))
  )
  err.message =
    err.message === enumErrorMessage.NET_WORK_MESSAGE
      ? enumErrorMessage.NET_WORK_MESSAGE
      : message || enumErrorMessage.UNKNOWN_MESSAGE
  switch (code) {
    case 401:
      // 客户端接口容错
      // eslint-disable-next-line no-case-declarations
      const { isInlineClient } = useClientBridgeRouteGuard()
      if (isInlineClient) {
        use_user.clearUserInfo(false)
        return useErrorMessage({ message: commonErrorCode["403"], duration: 15000 })
      }

      // 401 没权限的话清空信息 刷新页面
      if (process.client) {
        use_user.clearUserInfo()
      }
      // // 有需要权限的路由
      // if (TOKEN_ROUTER.includes(useRoute().path)) {
      //   // 暂时跳首页
      // }
      return reject(err)
    default:
      break
  }
  if (!noMessageTipDict.includes(code)) {
    useErrorMessage(err.message)
  }
  return reject(err)
}

export const fetch = <K = any, T = ResponseConfig<K>["data"]>(
  url: string,
  options?: FetchOptions,
  isTotalData?: boolean
): Promise<T> => {
  return new Promise((resolve, reject) => {
    Promise.race([
      $fetch(url, {
        ...options,
        baseURL: baseUrl,
        headers: {
          Authorization: getToken(),
          Language: getHeadLanguage(), // 语言标识
          "Ev-Point": stHeaderEncodeURI(),
          ...(options.headers || {})
        }
      }),
      new Promise(function (_resolve, _reject) {
        setTimeout(() => _reject(new Error(enumErrorMessage.NET_WORK_MESSAGE)), TIMEOUT_TIME)
      })
    ])
      .then((data: ResponseConfig) => {
        const code = data.code
        if (data instanceof Blob) {
          resolve(data)
        }
        switch (code) {
          case 0:
          case 200: {
            isTotalData ? resolve(data) : resolve(data.data)
          }
        }
        reject(data)
      })
      .catch((err: any) => {
        handlerError(reject, err)
      })
  })
}

export const xhrFetch = <K = any, T = ResponseConfig<K>["data"]>(
  url: string,
  options?: FetchOptions,
  isTotalData?: boolean,
  onProcess?: (event: ProgressEvent) => void // 允许外部传入的进度回调
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    const timeoutId = setTimeout(() => {
      xhr.abort() // 超时时，终止请求
      reject(new Error(enumErrorMessage.NET_WORK_MESSAGE))
    }, TIMEOUT_TIME) // 设置超时时间

    xhr.open(options?.method || "GET", url, true) // 默认 GET 请求

    // 设置请求头
    xhr.setRequestHeader("Authorization", getToken())
    xhr.setRequestHeader("Language", getHeadLanguage())
    xhr.setRequestHeader("Ev-Point", stHeaderEncodeURI())
    if (options?.headers) {
      Object.keys(options.headers).forEach((key) => {
        xhr.setRequestHeader(key, options.headers[key])
      })
    }

    // 设置响应类型
    xhr.responseType = "json"
    // 如果传入了 onProcess，监听进度
    if (onProcess) {
      xhr.upload.onprogress = (event) => {
        onProcess(event) // 调用外部传入的进度处理函数
      }
    }

    // 请求完成后的处理
    xhr.onload = () => {
      clearTimeout(timeoutId) // 清除超时计时器
      const data: ResponseConfig = xhr.response
      // 处理响应
      if (xhr.status === 200 || xhr.status === 0) {
        if (data instanceof Blob) {
          resolve(data) // 如果响应是 Blob 类型，直接返回
        } else {
          const code = data.code
          switch (code) {
            case 0:
            case 200:
              isTotalData ? resolve(data) : resolve(data.data) // 根据需要返回全部数据或只返回 `data`
              break
            default:
              reject(data) // 非正常响应，拒绝返回的结果
              break
          }
        }
      } else {
        reject(data)
      }
    }

    // 请求错误时的处理
    xhr.onerror = (err) => {
      clearTimeout(timeoutId) // 清除超时计时器
      handlerError(reject, err)
    }

    // 如果请求是 POST 方法且有请求体，并且 body 是 FormData
    if (options?.body instanceof FormData) {
      // 不需要设置 Content-Type，因为 FormData 会自动处理它
      xhr.send(options.body) // 直接发送 FormData
    } else if (options?.body) {
      // 如果是普通的 JSON 或其他格式的请求体
      xhr.setRequestHeader("Content-Type", "application/json")
      xhr.send(JSON.stringify(options.body)) // 发送 JSON 格式的请求体
    } else {
      xhr.send() // 不带请求体，直接发送
    }
  })
}

export default new (class Http {
  get<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    params?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "get",
        params,
        headers: {
          "Content-Type": "application/json"
        },
        ...options
      },
      isTotalData
    )
  }

  post<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "post",
        body,
        headers: {
          "Content-Type": "application/json"
        },
        ...options
      },
      isTotalData
    )
  }

  put<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "put",
        body,
        ...options
      },
      isTotalData
    )
  }

  xhrPost<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: FetchOptions,
    isTotalData?: boolean,
    onProcess?: (event: ProgressEvent) => void // 允许外部传入的进度回调
  ): Promise<T> {
    return xhrFetch(
      url,
      {
        method: "post",
        body,
        headers: {
          "Content-Type": "application/json"
        },
        ...options
      },
      isTotalData,
      onProcess
    )
  }
})()
