import { createVNode, render } from "vue"

export const useCommonGlobalLoading = () => {
  const show = ref(false)
  let container: HTMLDivElement | null = null

  const openDialog = () => {
    if (container) return // 避免重复创建

    const com = resolveComponent("CommonLoading")
    const vm = createVNode(com, { show }) // 这里直接传 ref，保持响应式
    const { vueApp } = useNuxtApp()

    vm.appContext = vueApp._context

    container = document.createElement("div")
    document.body.appendChild(container)

    render(vm, container)
    show.value = true
  }

  const closeDialog = () => {
    if (container) {
      render(null, container) // 卸载组件
      document.body.removeChild(container)
      container = null
    }
    show.value = false
  }

  return { show, openDialog, closeDialog }
}
