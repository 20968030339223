<script lang="tsx">
import { defineComponent, PropType } from "vue"
import {
  IFormPropsFormModel,
  IFormPropsLabelPosition,
  IFormPropsInputList,
  IFormPropFormSize,
  IFormInputListConfig
} from "@/types/components/common/form"
import type { FormRules } from "element-plus"
import CommonFormItemRender from "./FormItemRender.vue"
import { ElForm, ElFormItem } from "element-plus"
export default defineComponent({
  name: "PixCommonSearch",
  components: {
    RenderSlots(prop) {
      return prop.renderSlots
    },
    ElForm,
    ElFormItem
  },
  props: {
    formInputList: {
      type: Array as PropType<IFormPropsInputList>,
      default() {
        return []
      }
    },
    formModel: {
      type: Object as PropType<IFormPropsFormModel>,
      default() {
        return {}
      }
    },
    formRules: {
      type: Object as PropType<FormRules>,
      default() {
        return {}
      }
    },
    formLabelWidth: {
      type: Number as PropType<number>,
      default() {
        return undefined
      }
    },
    formLabelPosition: {
      type: String as PropType<IFormPropsLabelPosition>,
      default() {
        return "right"
      }
    },
    formInline: {
      type: Boolean as PropType<boolean>,
      default() {
        return true
      }
    },
    formSize: {
      type: String as PropType<IFormPropFormSize>,
      default() {
        return "large"
      }
    },
    formExtend: {
      // 其他传入el-form的属性
      type: Object as PropType<Record<string, any>>,
      default() {
        return {}
      }
    }
  },
  emits: ["buttonClick", "mainBtnClick", "validate"],
  methods: {
    clearForms() {
      this.$refs.formRef && this.$refs.formRef.clearValidate()
    },
    clearValidateField(prop) {
      this.$refs.formRef && this.$refs.formRef.clearValidate(prop)
    },
    validateField(props) {
      return this.$refs.formRef && this.$refs.formRef.validateField(props)
    },
    validate() {
      this.$refs.formRef &&
        this.$refs.formRef.validate((v) => {
          this.$emit("validate", v)
        })
    },
    renderSlots(item: IFormInputListConfig) {
      return item.slotsFormatter
        ? item.slotsFormatter(item)
        : item.slotsName
        ? this.$slots[item.slotsName]
          ? this.$slots[item.slotsName]!(item)
          : null
        : null
    }
  },
  render() {
    return (
      <div class="pix-common-search">
        <el-form
          onSubmit={(e) => {
            if (e) {
              e.preventDefault()
            }
          }}
          {...this.formExtend}
          ref="formRef"
          inline={this.formInline}
          model={this.formModel}
          rules={this.formRules}
          label-width={this.formLabelWidth}
          label-position={this.formLabelPosition}
          size={this.formSize}
        >
          {this.formInputList.map((item, i) => {
            return (
              <>
                {(item.showItem === undefined || item.showItem) && (
                  <el-form-item
                    key={i}
                    prop={item.model}
                    style={item.formStyle}
                    label={item.label}
                    class={item.extraClass}
                  >
                    {{
                      label:
                        item.labelSlots &&
                        (() => {
                          return item.labelSlots(item)
                        }),
                      default: () => {
                        return (
                          <>
                            {item.prefixChildSlots && item.prefixChildSlots(item)}
                            <CommonFormItemRender config={item} model={this.formModel} />
                            {item.childSlots && item.childSlots(item)}
                          </>
                        )
                      }
                    }}
                  </el-form-item>
                )}
                {item.slots && <render-slots renderSlots={this.renderSlots(item)} />}
              </>
            )
          })}
        </el-form>
      </div>
    )
  }
})
</script>

<style lang="scss" scoped>
.pix-common-search {
  :deep(.el-form-item) {
    .el-form-item__label {
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;
    }
    .country-select {
      height: 39px;
      line-height: 39px;
    }
    .el-form-item__content {
      .el-input__wrapper {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        box-shadow: none;
        padding: 0 12px;
        &.is-focus {
        }
        .el-input__inner {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.85);
          height: 36px;
        }
      }
      .el-textarea__inner {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        box-shadow: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number--large {
        width: 100%;
      }

      .el-input-number .el-input__inner {
        text-align: left;
      }
    }
    .el-select {
      width: 100%;
      .el-input.is-focus .el-input__wrapper,
      .el-input__wrapper.is-focus {
        box-shadow: none !important;
      }
    }
  }
}
</style>
