import { LOGIN_SCENE, IForgetPsdType, IErrorLoginType, VERIFY_TYPE, SignFromType } from "@/constant/login"
import { useUser } from "@/store/user"
import { LocalStorageKey, SessStorageKey } from "@/constant/cache"
import { useOriginPath } from "~~/constant/useLocalPathConfig"

import {
  INVITEE_PAGE_PATH,
  PROFILE_PAGE_PATH,
  HELP_PAGE_PATH,
  LANDING_COMBO_PAGE,
  PAYMENT_PAGE_PATH,
  LANDING_FREE_TRIAL
} from "@/constant/route" // 邀请注册页面路由
import { VerifyLogInfo } from "~/constant/pages/dialog/securityLoginDialog"
import { isFromWebEditor } from "@/utils/feature"

export const useLoginAndRegFlow = () => {
  const res_email = ref()
  const res_phone = ref<{
    countryValue: string
    inputValue: string
  }>()
  const user_exist = ref()
  const user_is_set_psd = ref()
  const show_login_code_change = ref(true)
  const show_login_psd_change = ref(true)

  const extra_email = ref()

  const extra_phone = ref<{
    countryValue: string
    inputValue: string
  }>()

  const codeRecord = ref()

  const addEmailVerifyValue = reactive<{ verify_type: VERIFY_TYPE; verify_code: string }>({
    verify_type: VERIFY_TYPE.email,
    verify_code: ""
  })

  const { _pointLoginAndRegFlowPageView, _pointLoginAndRegFlowPageButtonView, _pointWebsitePopupView } = useCommonMp()
  const {
    _pointLoginSignUpClick,
    _pointSignUpWithCodeClick,
    _pointLogInWithCodeClick,
    _pointSignUpAddMailClick,
    _pointVerifyEmailClick,
    _pointEnterPwdClick,
    _pointResetPwdClick,
    _pointCheckCodeClick,
    _pointPopUpClick
  } = useStSdkCommonMp()
  // 登录或者注册输入邮箱弹窗
  const useSignInOrSignUpDialog = () => {
    let closeDialog = null
    const { sidPointTestAll } = useSdkMapTestHook()
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      async loadSuccess() {
        _pointPopUpClick({
          popup_name: "Log in / Sign Up"
        })
        _pointLoginAndRegFlowPageView({ pageTitle: "main_register_page", featureName: "main" })
        try {
          await getValidatePoint()
          sidPointTestAll()
        } catch (e) {
          sidPointTestAll("sid_error")
        }
      },
      close() {
        _pointLoginSignUpClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_name: "main"
        })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("CommonNewLoginSignInOrSignUp")
        const signFrom = getLocalStorage(SessStorageKey.SIGN_FROM_TYPE, "sessionStorage") // 登陆来源
        const signTip = signFrom === SignFromType.Export ? useTranslateI18n("webEditor.trial.signTip") : ""
        return (
          <com
            onMainClick={({ email, exist, is_set_psd, phone }) => {
              user_exist.value = exist
              res_email.value = email
              user_is_set_psd.value = is_set_psd
              res_phone.value = phone
              // 走登录流程
              if (exist) {
                if (user_is_set_psd.value) {
                  useLogInWithPsdDialog()
                } else {
                  show_login_psd_change.value = is_set_psd
                  useLogInWithCodeDialog()
                }
              } else {
                // 走注册流程
                useSignUpWithCodeDialog()
              }
              closeDialog()
            }}
            phone={res_phone.value}
            onCloseSingUp={closeDialog}
          >
            {{ signTip: () => signTip && <p class="sign-tip">{signTip}</p> }}
          </com>
        )
      }
    })
  }

  // 输入验证码登录弹窗
  const useLogInWithCodeDialog = () => {
    let closeDialog = null
    const goEmail = () => {
      _pointLoginAndRegFlowPageButtonView({
        featureName: "login",
        elementName: "change_account",
        eventType: res_phone.value ? "phone" : "email"
      })
      closeDialog()
      useSignInOrSignUpDialog()
    }
    const goPsdDialog = () => {
      closeDialog()
      useLogInWithPsdDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      loadSuccess() {
        _pointLoginAndRegFlowPageView({ pageTitle: "login_with_code", featureName: "login" })
      },
      close() {
        _pointLogInWithCodeClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "login",
          feature_name: "login_with_code"
        })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("CommonNewLoginWithCode")
        return (
          <com
            email={res_email.value}
            phone={res_phone.value}
            onGoBack={goEmail}
            onGoEnterPsd={goPsdDialog}
            showPsdChange={show_login_psd_change.value}
            closeDialog={close}
          />
        )
      }
    })
  }

  // 输入密码登录弹窗
  const useLogInWithPsdDialog = () => {
    let closeDialog = null
    const goEmail = () => {
      _pointEnterPwdClick({
        element_name: res_phone.value ? "change_phone_number" : "change_email_address",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "login",
        feature_name: "enter_password"
      })
      closeDialog()
      useSignInOrSignUpDialog()
    }
    const goWidthCodeDialog = () => {
      _pointEnterPwdClick({
        element_name: "login_with_code",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "login",
        feature_name: "enter_password"
      })
      closeDialog()
      useLogInWithCodeDialog()
    }
    const goForgetPsd = () => {
      _pointEnterPwdClick({
        element_name: "forget_password",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "login",
        feature_name: "enter_password"
      })
      closeDialog()
      useForgetPsdEnterEmailDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      loadSuccess() {
        _pointLoginAndRegFlowPageView({ pageTitle: "login_with_password", featureName: "login" })
      },
      close() {
        _pointEnterPwdClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "login",
          feature_name: "enter_password"
        })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginWithPsd")
        return (
          <com
            showCodeChange={show_login_code_change.value}
            phone={res_phone.value}
            email={res_email.value}
            onGoBack={goEmail}
            onGoWithCode={goWidthCodeDialog}
            onGoForgetPsd={goForgetPsd}
            closeDialog={close}
          />
        )
      }
    })
  }

  // 忘记密码输入邮箱或者电话弹窗
  const useForgetPsdEnterEmailDialog = () => {
    let closeDialog = null
    const goBack = () => {
      _pointResetPwdClick({
        element_name: "return_to_login",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "reset_password",
        feature_name: "reset_password"
      })
      closeDialog()
      useLogInWithPsdDialog()
    }
    const submit = (form) => {
      _pointResetPwdClick({
        element_name: "submit",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "reset_password",
        feature_name: "reset_password"
      })
      form.email && (extra_email.value = form.email)
      form.phoneConfig && (extra_phone.value = form.phoneConfig)
      closeDialog()
      useForgetPsdEnterEmailAndCodeDialog()
    }
    useCommonNormalDialog({
      close() {
        _pointResetPwdClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "reset_password",
          feature_name: "reset_password"
        })
      },
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginForgetResetPsd")
        return (
          <com
            type={IForgetPsdType.Type1}
            registryQuery={{
              email: res_email.value,
              phone: res_phone.value
            }}
            onGoBack={goBack}
            onSubmitReset={submit}
          />
        )
      }
    })
  }

  // 忘记密码输入完邮箱或者电话进入验证码输入弹窗
  const useForgetPsdEnterEmailAndCodeDialog = () => {
    let closeDialog = null
    const goBack = () => {
      _pointCheckCodeClick({
        element_name: extra_email.value ? "change_email_address" : "change_phone_number",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "reset_password",
        feature_name: "check_code"
      })
      closeDialog()
      useForgetPsdEnterEmailDialog()
    }
    const submit = async ({ code, query }) => {
      _pointCheckCodeClick({
        element_name: "done",
        element_position: "popup",
        element_type: "button",
        popup_name: "login_or_signup",
        feature_module: "login_or_signup",
        feature_group: "reset_password",
        feature_name: "check_code"
      })
      // 记录验证码返回的code
      codeRecord.value = code
      // 验证通过显示输入密码重置
      closeDialog()
      useForgetPsdEnterEmailAndPsdDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      close: () => {
        _pointCheckCodeClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "reset_password",
          feature_name: "check_code"
        })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")
        return (
          <com
            sceneRecord={LOGIN_SCENE.FORGET_PSD}
            registryQuery={{ email: extra_email.value, phone: extra_phone.value }}
            onGoBack={goBack}
            onSubmitCode={submit}
          />
        )
      }
    })
  }

  // 忘记密码显示密码框
  const useForgetPsdEnterEmailAndPsdDialog = () => {
    let closeDialog = null
    const goBack = () => {
      closeDialog()
      useLogInWithPsdDialog()
    }
    // 设置密码
    const submit = async (form) => {
      try {
        await forgetPsdApi({
          email: extra_email.value,
          phone: extra_phone.value?.inputValue,
          phone_cc: extra_phone.value?.countryValue,
          verify_code: codeRecord.value,
          password: useXorEncrypt(form.password)
        })
        useSuccessMessage(useTranslateI18n("login.message.psdEdit"))
      } finally {
        closeDialog()
      }
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginForgetResetPsd")
        return (
          <com
            type={IForgetPsdType.Type2}
            registryQuery={{ email: extra_email.value, phone: extra_phone.value }}
            onGoBack={goBack}
            onSubmitReset={submit}
          />
        )
      }
    })
  }

  // 编辑密码弹窗邮箱验证
  const useEditPsdEnterEmailDialog = (value: VERIFY_TYPE) => {
    let closeDialog = null
    const use_user = useUser()
    const submit = async ({ code, query }) => {
      try {
        // 记录验证码返回的code
        codeRecord.value = code
        // 验证通过显示输入密码重置
        closeDialog()
        useEditPsdEnterPsdDialog(value)
      } catch (e) {}
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")
        const result =
          value === VERIFY_TYPE.email
            ? {
                email: use_user.userInfo.email
              }
            : {
                phone: {
                  inputValue: use_user.userInfo.phone,
                  countryValue: use_user.userInfo.phone_cc
                }
              }
        return (
          <com sceneRecord={LOGIN_SCENE.EDIT_PSD} showChangeText={false} registryQuery={result} onSubmitCode={submit} />
        )
      }
    })
  }

  // 编辑密码输入密码框
  const useEditPsdEnterPsdDialog = (value: VERIFY_TYPE) => {
    let closeDialog = null
    const use_user = useUser()
    const result =
      value === VERIFY_TYPE.email
        ? {
            email: use_user.userInfo.email
          }
        : {
            phone: {
              inputValue: use_user.userInfo.phone,
              countryValue: use_user.userInfo.phone_cc
            }
          }

    const _params =
      value === VERIFY_TYPE.email
        ? {
            email: use_user.userInfo.email
          }
        : {
            phone: use_user.userInfo.phone,
            phone_cc: use_user.userInfo.phone_cc
          }
    // 设置密码
    const submit = async (form) => {
      try {
        await changePsdApi({
          verify_code: codeRecord.value,
          password: useXorEncrypt(form.password),
          ..._params
        })
        useSuccessMessage(useTranslateI18n("login.message.psdEdit"))
        useOfflineReminderDialog()
        // setTimeout(() => {
        //   use_user.clearUserInfo()
        //   // showLoginDialogHandler()
        // }, 3000)
      } finally {
        closeDialog()
      }
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginForgetResetPsd")

        return <com type={IForgetPsdType.Type3} registryQuery={result} onSubmitReset={submit} />
      }
    })
  }

  // 删除 email、phone
  const useDeleteEmailOrPhoneDialog = (value: VERIFY_TYPE, unbind_type: VERIFY_TYPE) => {
    let closeDialog = null
    const use_user = useUser()
    const result =
      value === VERIFY_TYPE.email
        ? {
            email: use_user.userInfo.email
          }
        : {
            phone: {
              inputValue: use_user.userInfo.phone,
              countryValue: use_user.userInfo.phone_cc
            }
          }
    const submit = async ({ code, query }) => {
      const queryResult =
        value === VERIFY_TYPE.email
          ? {
              email: use_user.userInfo.email
            }
          : {
              phone: use_user.userInfo.phone,
              phone_cc: use_user.userInfo.phone_cc
            }
      await postUnbindEmailOrPhone({
        unbind_type: unbind_type,
        ...queryResult,
        code
      })
      await use_user.setAllUserInfo()
      closeDialog()
      // try {
      //   const resCode = useMd5(code.join(""))
      //   const data = await checkVerCodeApi({
      //     code: resCode,
      //     email: query.email,
      //     phone: query.phone?.inputValue,
      //     phone_cc: query.phone?.countryValue,
      //     scene: LOGIN_SCENE.EDIT_PSD
      //   })
      //   // 记录验证码返回的code
      //   codeRecord.value = data.code
      //   // 验证通过显示输入密码重置
      //   closeDialog()
      //   useEditPsdEnterPsdDialog(value)
      // } catch (e) {}
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")

        return (
          <com
            sceneRecord={LOGIN_SCENE.VERIFY_EMAIL_CODE}
            showChangeText={false}
            registryQuery={result}
            onSubmitCode={submit}
          />
        )
      }
    })
  }

  // 添加phone或者email
  const useAddPhoneSubmitDialog = (value: {
    phone_cc?: string
    phone?: string
    email?: string
    phone_code?: string
    email_code?: string
  }) => {
    return new Promise((resolve, reject) => {
      let closeDialog = null
      const use_user = useUser()
      const result = value.email
        ? {
            email: value.email
          }
        : {
            phone: {
              inputValue: value.phone,
              countryValue: value.phone_cc
            }
          }

      const submit = async ({ code, query }) => {
        const extra_params = value.email
          ? {
              phone_code: value.phone_code,
              email_code: code,
              email: value.email,
              phone: use_user.userInfo.phone,
              phone_cc: use_user.userInfo.phone_cc
            }
          : {
              email_code: value.email_code,
              phone_code: code,
              phone_cc: value.phone_cc,
              phone: value.phone,
              email: use_user.userEmail
            }
        const bind_type = value.email ? VERIFY_TYPE.email : VERIFY_TYPE.phone
        await postBindEmailOrPhone({
          bind_type: bind_type,
          ...extra_params
        })

        await use_user.setAllUserInfo()

        resolve(true)

        closeDialog()
      }
      useCommonNormalDialog({
        close() {
          resolve(true)
        },
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          closeDialog = close
          const com = resolveComponent("LazyCommonNewLoginAccountEmail")

          return (
            <com
              sceneRecord={LOGIN_SCENE.VERIFY_EMAIL_CODE}
              showChangeText={false}
              registryQuery={result}
              onSubmitCode={submit}
            />
          )
        }
      })
    })
  }

  // fb填写邮箱dialog
  const useFbEnterEmailDialog = () => {
    let closeDialog = null
    const submit = (form) => {
      extra_email.value = form.email
      closeDialog()
      useFbEnterCodeDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginForgetResetPsd")
        return (
          <com
            type={IForgetPsdType.Type4}
            registryQuery={{ email: "@" }}
            eval_email={false}
            validator_email={true}
            custom_title={useTranslateI18n("login.forgetPsd.title2")}
            onSubmitReset={submit}
          />
        )
      }
    })
  }

  // fb填写邮箱发送的验证码
  const useFbEnterCodeDialog = () => {
    let closeDialog = null
    const use_user = useUser()
    const { setInviteeSuccessFlag } = useInviteeSuccessFlag()
    const { _PointGtmSignUpSuccess } = useLoginGtm()
    const goBack = () => {
      closeDialog()
      useFbEnterEmailDialog()
    }
    const { getSSORedirectUrl } = useSSO()
    const ssoRedirectUrl = getSSORedirectUrl()
    const { path } = useRoute()
    const isLanding = useLandingPageStaticPath(path)
    const isFeature = useFeaturePage(path)

    const submit = async ({ code, query }) => {
      // 记录验证码返回的code
      codeRecord.value = code
      // 验证通过显示输入密码重置
      closeDialog()

      // 同意协议
      usePolicyCheckboxDialog({
        async submit({ showDialog }) {
          try {
            const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
            const data = await setStateLoginApi({
              email: query.email,
              verify_code: codeRecord.value,
              uuid: use_user.common_uuid,
              ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
            })

            use_user.setTk(data.token)

            // 注册成功上报到gtm
            _PointGtmSignUpSuccess({
              sha256_email_address: query.email
            })
            // 落地页需要跳过成功和绑定手机号码流程(Feature页也过滤)
            if (!isLanding && !isFeature) {
              // 邮箱注册成功标识
              setLocalStorage(LocalStorageKey.OTHER_SIGNUP_DIALOG, "1")
              // 新用户弹窗是否绑定手机号码
              await useProfileAddPhoneOrEmailDialog({ value: VERIFY_TYPE.phone })
            }

            if (use_user.isBindPhone) {
              _PointGtmSignUpSuccess({
                sha256_email_address: query.email,
                sha256_phone_number: use_user.getFullPhoneCode
              })
            }
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              redirectUrl: ssoRedirectUrl
            })
          } catch (e) {
            // 判断用户是否开启二次认证了，如果是二次认证的话
            // if (e?.data?.code === IErrorLoginType.RepeatVerify) {
            //   const { showRepeatAuthDialog } = useUserAuthRepeatDialog()
            //   showRepeatAuthDialog(
            //     {
            //       email: query.email
            //     },
            //     true,
            //     ""
            //   )
            // } else if (e?.data?.code === IErrorLoginType.Retrieve) {
            //   const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
            //   showRetrieveDialog(query.email, e?.data?.data?.recover_token, true, "")
            // }
          } finally {
            showDialog.value = false
          }
        },
        closeDialog() {
          if (useRoute().path === unref(INVITEE_PAGE_PATH)) {
            location.reload()
          }
        }
      })
      // 如果是邀请页面 设置邀请成功标识
      if (useRoute().path === unref(INVITEE_PAGE_PATH)) {
        setInviteeSuccessFlag()
      }
    }

    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")
        return (
          <com
            sceneRecord={LOGIN_SCENE.LOGIN}
            registryQuery={{ email: extra_email.value }}
            onGoBack={goBack}
            onSubmitCode={submit}
          />
        )
      }
    })
  }

  // 输入验证码注册弹窗
  const useSignUpWithCodeDialog = () => {
    let closeDialog = null
    const goEmail = () => {
      _pointLoginAndRegFlowPageButtonView({
        featureName: "register",
        elementName: "change_account",
        eventType: res_phone.value ? "phone" : "email"
      })
      closeDialog()
      useSignInOrSignUpDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      loadSuccess() {
        _pointLoginAndRegFlowPageView({ pageTitle: "register_with_code", featureName: "register" })
      },
      close() {
        _pointSignUpWithCodeClick({
          element_name: "close",
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "signup",
          feature_name: "signup_with_code"
        })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginSignUpWithCode")
        return <com email={res_email.value} phone={res_phone.value} onGoBack={goEmail} closeDialog={close} />
      }
    })
  }

  // 注册成功是否设置密码
  const useSignUpWithPsdDialog = () => {
    let closeDialog = null
    // 跳过密码设置
    const skipPsdHandler = () => {
      closeDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      loadSuccess() {
        _pointLoginAndRegFlowPageView({ pageTitle: "set_password", featureName: "register" })
      },
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginSignUpWithPsd")
        return <com onSkip={skipPsdHandler} onSubmit={skipPsdHandler} point={"register"} />
      }
    })
  }

  // 手机注册成功添加email
  const usePhoneSignUpAddEmailDialog = (update: boolean, res?: any) => {
    return new Promise((resolve, reject) => {
      const use_user = useUser()
      const { _PointGtmSignUpSuccess } = useLoginGtm()
      const addEmailFn = () => {
        _pointSignUpAddMailClick({
          element_position: "popup",
          element_type: "button",
          popup_name: "login_or_signup",
          feature_module: "login_or_signup",
          feature_group: "signup",
          feature_name: "add_email",
          element_name: "continue"
        })
      }
      useCommonNormalDialog({
        showCloseIcon: false,
        loadSuccess() {
          _pointLoginAndRegFlowPageView({ pageTitle: "register", featureName: "add_email" })
        },
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonNewLoginPhoneSignInAddEmail")
          return (
            <com
              onAddEmail={addEmailFn}
              onSubmit={async (email) => {
                close()
                if (update) {
                  await postUpdateEdm(email)
                  _PointGtmSignUpSuccess({
                    sha256_email_address: email,
                    sha256_phone_number: use_user.getFullPhoneCode
                  })
                }
                resolve(email)
                update && usePhoneSignUpAddEmailCodeDialog({ email, showCloseIcon: true, update, res })
              }}
            />
          )
        }
      })
    })
  }

  // 手机号添加email后校验验证码
  const usePhoneSignUpAddEmailCodeDialog = ({
    email,
    showCloseIcon,
    update,
    res
  }: {
    email: string
    showCloseIcon: boolean
    update: boolean
    res?: any
  }): Promise<{ code?: string; email?: string }> => {
    const use_user = useUser()
    const { _PointGtmSignUpSuccess } = useLoginGtm()
    let closeFlag = false
    return new Promise((resolve, reject) => {
      useCommonNormalDialog({
        showCloseIcon,
        close() {
          _pointVerifyEmailClick({
            element_name: "close",
            element_position: "popup",
            element_type: "button",
            popup_name: "login_or_signup",
            feature_module: "login_or_signup",
            feature_group: "signup",
            feature_name: "verify_email"
          })
          !closeFlag && resolve({})
        },
        loadSuccess() {
          !use_user.isLogin &&
            _pointLoginAndRegFlowPageView({ pageTitle: "register", featureName: "verify_your_email" })

          use_user.isLogin &&
            _pointWebsitePopupView({
              featureModule: "account_setting",
              pageTitle: "verify_your_email"
            })
        },
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonNewLoginPhoneAccountEmail")
          return (
            <com
              email={email}
              onClose={() => {
                _pointLoginAndRegFlowPageButtonView({
                  featureName: !use_user.isLogin ? "register" : "login",
                  elementName: "do_it_later",
                  pageTitle: "verify_your_email"
                })
                _pointVerifyEmailClick({
                  element_name: "do_it_later",
                  element_position: "popup",
                  element_type: "button",
                  popup_name: "login_or_signup",
                  feature_module: "login_or_signup",
                  feature_group: "signup",
                  feature_name: "verify_email"
                })
                close()
                resolve({})
                res && res()
              }}
              onGoBack={async () => {
                _pointLoginAndRegFlowPageButtonView({
                  featureName: !use_user.isLogin ? "register" : "login",
                  elementName: "change_account",
                  eventType: "email"
                })
                closeFlag = true
                close()
                await usePhoneSignUpAddEmailDialog(update, res || resolve)
              }}
              onPointSubmit={() => {
                _pointLoginAndRegFlowPageButtonView({
                  featureName: !use_user.isLogin ? "register" : "login",
                  elementName: "continue",
                  pageTitle: "verify_your_email"
                })
                _pointVerifyEmailClick({
                  element_name: "continue",
                  element_position: "popup",
                  element_type: "button",
                  popup_name: "login_or_signup",
                  feature_module: "login_or_signup",
                  feature_group: "signup",
                  feature_name: "verify_email"
                })
              }}
              onSubmitCode={async ({ code, email, agree_mail_sub }) => {
                closeFlag = true
                close()
                await postVerifyEdm({ code, email, agree_mail_sub })
                await use_user.setAllUserInfo()

                _PointGtmSignUpSuccess({
                  sha256_email_address: email,
                  sha256_phone_number: use_user.getFullPhoneCode
                })

                resolve({
                  code,
                  email
                })

                res && res()
              }}
            />
          )
        }
      })
    })
  }

  // 其他注册成功弹窗成功进入个人中心
  const useOtherSignupEnterProfileDialog = () => {
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        const com = resolveComponent("LazyCommonNewLoginSignupSuccess")
        return (
          <com
            onClose={() => {
              close()
              const { deviceType } = useDevice()
              const { download } = useDownload()
              download(deviceType.value)
            }}
            onSubmit={() => {
              close()
              navigateTo({
                path: unref(PAYMENT_PAGE_PATH),
                query: {
                  ...useRoute().query
                }
              })
            }}
          />
        )
      }
    })
  }

  // 账号选择是email还是phone
  const useVerifySelectDialog = () => {
    return new Promise((resolve, reject) => {
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonNewLoginVerifySelect")
          return (
            <com
              onClose={close}
              onSubmit={(value) => {
                close()
                resolve(value)
              }}
            />
          )
        }
      })
    })
  }

  // Add Phone or Email，先验证Phone or Email（二次验证需要修改sceneRecord：LOGIN_SCENE.REPEAT_AUTH）
  const useBeforeAddPhoneOrEmail = (
    value: { phone_cc?: string; phone?: string; email?: string; user_email?: string },
    scene = LOGIN_SCENE.VERIFY_EMAIL_CODE
  ) => {
    const { email, user_email, phone, phone_cc } = value
    return new Promise<string>((resolve, reject) => {
      let closeDialog = null
      const use_user = useUser()
      const result = value.email
        ? {
            email,
            user_email
          }
        : {
            phone: {
              inputValue: phone,
              countryValue: phone_cc,
              user_email
            }
          }

      const submit = async ({ code, query }) => {
        resolve(code)
        closeDialog()
      }
      useCommonNormalDialog({
        close() {
          resolve("")
        },
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          closeDialog = close
          const com = resolveComponent("LazyCommonNewLoginAccountEmail")

          return (
            <com
              sceneRecord={scene}
              countId={"_before_add"}
              showChangeText={false}
              registryQuery={result}
              onSubmitCode={submit}
            />
          )
        }
      })
    })
  }

  // 个人中心Add Phone or Email
  const useProfileAddPhoneOrEmailDialog = ({
    value,
    email_code,
    phone_code
  }: {
    value: VERIFY_TYPE
    email_code?: string
    phone_code?: string
  }) => {
    return new Promise((resolve, reject) => {
      const use_user = useUser()
      let closeFlag = false
      useCommonNormalDialog({
        close() {
          !closeFlag && resolve(true)
        },
        loadSuccess() {
          const featureNameDict = {
            [VERIFY_TYPE.email]: "add_email",
            [VERIFY_TYPE.phone]: "add_phone"
          }

          !use_user.isLogin &&
            _pointLoginAndRegFlowPageView({ featureName: "register", pageTitle: featureNameDict[value] })

          use_user.isLogin &&
            _pointWebsitePopupView({
              featureModule: "account_setting",
              pageTitle: featureNameDict[value]
            })
        },
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonNewLoginAddPhoneOrEmail")
          return (
            <com
              onClose={() => {
                close()
                resolve(true)
              }}
              type={value}
              onSubmit={async ({ email, phoneConfig }) => {
                closeFlag = true
                close()
                if (value === VERIFY_TYPE.email) {
                  await useAddPhoneSubmitDialog({ email, phone_code })
                  resolve(true)
                } else {
                  !use_user.isLogin &&
                    _pointLoginAndRegFlowPageView({ featureName: "register", pageTitle: "verify_your_phone" })

                  use_user.isLogin &&
                    _pointWebsitePopupView({
                      featureModule: "account_setting",
                      pageTitle: "verify_your_phone"
                    })

                  await useAddPhoneSubmitDialog({
                    phone_cc: phoneConfig.countryValue,
                    phone: phoneConfig.inputValue,
                    email_code
                  })
                  resolve(true)
                }
              }}
            />
          )
        }
      })
    })
  }

  // 个人中心改变邮箱弹窗验证
  const useChangeEmailVerifyDialog = (value: VERIFY_TYPE) => {
    let closeDialog = null
    const use_user = useUser()
    addEmailVerifyValue.verify_type = value
    const submit = async ({ code, query }) => {
      try {
        // 记录验证码返回的code
        addEmailVerifyValue.verify_code = code
        useChangeEmailEnterEmailDialog()
        closeDialog()
      } catch (e) {}
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")
        const result =
          value === VERIFY_TYPE.email
            ? {
                email: use_user.userInfo.email
              }
            : {
                phone: {
                  inputValue: use_user.userInfo.phone,
                  countryValue: use_user.userInfo.phone_cc
                }
              }
        return (
          <com
            countId={"change_email_f"}
            sceneRecord={LOGIN_SCENE.VERIFY_EMAIL_CODE}
            showChangeText={false}
            registryQuery={result}
            onSubmitCode={submit}
          />
        )
      }
    })
  }

  // 个人中心改变邮箱 输入邮箱
  const useChangeEmailEnterEmailDialog = () => {
    return new Promise((resolve, reject) => {
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonNewLoginPhoneSignInAddEmail")
          return (
            <com
              topTitle={useTranslateI18n("login.email.change1")}
              onSubmit={async (email) => {
                close()
                useChangeEmailAfterVerifyCodeDialog(email)
                resolve(email)
              }}
            />
          )
        }
      })
    })
  }

  // 个人中心改变邮箱输入邮箱后验证弹窗
  const useChangeEmailAfterVerifyCodeDialog = (email: string) => {
    let closeDialog = null
    const submit = async ({ code, query }) => {
      try {
        await postChangeIdentifierEmail({
          email,
          bind_type: VERIFY_TYPE.email,
          bind_code: code,
          verify_code: addEmailVerifyValue.verify_code,
          verify_type: addEmailVerifyValue.verify_type
        })
        useSuccessMessage(useTranslateI18n("login.email.changeSuccess", [email]))
        const use_user = useUser()
        use_user.setAllUserInfo()
        closeDialog()
        useOfflineReminderDialog()
      } catch (e) {}
    }
    const goBack = () => {
      closeDialog && closeDialog()
      useChangeEmailEnterEmailDialog()
    }
    useCommonNormalDialog({
      popperClass: "sign-in-or-sign-up-wrapper-dialog",
      contentSlots({ close }) {
        closeDialog = close
        const com = resolveComponent("LazyCommonNewLoginAccountEmail")
        const result = {
          email
        }
        return (
          <com
            countId={"change_email_l"}
            sceneRecord={LOGIN_SCENE.VERIFY_EMAIL_CODE}
            registryQuery={result}
            onSubmitCode={submit}
            onGoBack={goBack}
          />
        )
      }
    })
  }

  // 二次认证设置密码
  const useSecuritySetPsdDialog = (parame?: { email: string; code: string }) => {
    return new Promise((resolve) => {
      let closeDialog = null
      // 设置密码
      const submit = async (form) => {
        await changePsdApi({
          verify_code: parame.code,
          email: parame.email,
          password: useXorEncrypt(form.password),
          scene: LOGIN_SCENE.VERIFY_EMAIL_CODE
        })
        closeDialog()
      }
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        showCloseIcon: false,
        close() {
          resolve(true)
        },
        contentSlots({ close }) {
          closeDialog = close
          const com = resolveComponent("LazyCommonNewLoginSignUpWithPsd")
          return <com skip={false} onSubmit={submit} title={useTranslateI18n("login.newLogin.t45")} />
        }
      })
    })
  }

  const useSecurityTypeSelectDialog = (info: VerifyLogInfo = {}) => {
    return new Promise<{ type: VERIFY_TYPE; formModel: any } | void>((resolve) => {
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        close: () => {
          resolve()
        },
        contentSlots({ close }) {
          const com = resolveComponent("LazyCommonSecurityLoginDialog")
          return (
            <com
              preInfo={info}
              onSelectType={(val) => {
                resolve(val)
                close()
              }}
            />
          )
        }
      })
    })
  }
  // 设置辅助邮箱校验身份（手机号or邮箱）
  const useVerifiedPhoneOrEmail = (value: VERIFY_TYPE): Promise<{ code?: string; query?: any }> => {
    const { userInfo } = useUser()
    return new Promise((resolve) => {
      let closeDialog = null
      const submit = (data) => {
        resolve(data)
        closeDialog()
      }
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          closeDialog = close
          const com = resolveComponent("LazyCommonNewLoginAccountEmail")
          const result =
            value === VERIFY_TYPE.email
              ? {
                  email: userInfo.email
                }
              : {
                  phone: {
                    inputValue: userInfo.phone,
                    countryValue: userInfo.phone_cc
                  }
                }
          return (
            <com
              countId={"recover_email_f"}
              sceneRecord={LOGIN_SCENE.VERIFY_EMAIL_CODE}
              showChangeText={false}
              registryQuery={result}
              onSubmitCode={submit}
            />
          )
        }
      })
    })
  }
  // 填写并校验辅助邮件
  const useFillAndVerify = () => {
    const { userEmail } = useUser()
    return new Promise<void>((resolve) => {
      let closeDialog = null
      const save = async (data: { email: string }) => {
        closeDialog()
        const { email } = data
        // 校验辅助邮箱是否被使用过
        await postCheckRecover({ email })
        const scene = LOGIN_SCENE.RECOVER_EMAIL
        const verify_code = await useBeforeAddPhoneOrEmail({ email, user_email: userEmail }, scene)
        if (verify_code) {
          await postBindRecover({
            verify_code,
            recover_email: email,
            scene
          })
          resolve()
          useSuccessMessage(useTranslateI18n(`profile.basicInformation.modified`))
        }
      }
      useCommonNormalDialog({
        popperClass: "sign-in-or-sign-up-wrapper-dialog",
        contentSlots({ close }) {
          closeDialog = close
          const com = resolveComponent("LazyPagesProfileRecoveryEmail")
          return <com onClose={closeDialog} onSave={save} />
        }
      })
    })
  }

  return {
    useSignInOrSignUpDialog,
    useLogInWithCodeDialog,
    useLogInWithPsdDialog,
    useSignUpWithCodeDialog,
    useSignUpWithPsdDialog,
    usePhoneSignUpAddEmailDialog,
    useForgetPsdEnterEmailDialog,
    useEditPsdEnterEmailDialog,
    useFbEnterEmailDialog,
    useForgetPsdEnterEmailAndPsdDialog,
    useOtherSignupEnterProfileDialog,
    useVerifySelectDialog,
    useProfileAddPhoneOrEmailDialog,
    usePhoneSignUpAddEmailCodeDialog,
    useDeleteEmailOrPhoneDialog,
    useBeforeAddPhoneOrEmail,
    useChangeEmailVerifyDialog,
    useChangeEmailEnterEmailDialog,
    useChangeEmailAfterVerifyCodeDialog,
    useSecuritySetPsdDialog,
    useSecurityTypeSelectDialog,
    useVerifiedPhoneOrEmail,
    useFillAndVerify
  }
}

// 显示注册成功后的弹窗
export const useShowSignUpSuccessDialog = () => {
  useDownload()
  const use_user = useUser()
  use_user.showSignUpSuccessDialog()
}

// 登陆注册成功后的试用弹窗(webEditor)
export const useSignUpSuccessTrialDialog = () => {
  const from = getLocalStorage(SessStorageKey.SIGN_FROM_TYPE, "sessionStorage")
  const { isConsumed } = useUser()
  // 有张数 且正在下载(直接下载弹窗)
  console.log("登陆成功：", isConsumed, from)
  if (isConsumed && from === SignFromType.Export) {
    useDownloadHdDialog()
  } else if (!isConsumed && from === SignFromType.Export) {
    // 无张数 且正在下载(先购买 后下载)
    useTrialPackageDialog({ fromType: from })
  } else if (from === SignFromType.Buy) {
    // 非下载（直接提示购买）
    useTrialPackageDialog({ fromType: from })
  }
  removeLocalStorage(SessStorageKey.SIGN_FROM_TYPE, "sessionStorage")
}

// 登录后不刷新页面的判断
export const useNoReflushLogin = () => {
  const pathArr = [LANDING_FREE_TRIAL].map((route) => useOriginPath(route.value))
  // isFromWebEditor为 webeditor判断 或者包含的路由
  return !!isFromWebEditor() || pathArr.includes(useOriginPath(useRoute().path))
}

// 登录后不刷新页面的回调
export const useNoReflushLoginAfterCallback = () => {
  const fromWebEditor = isFromWebEditor()
  const { setNoRefreshCallbackStateRoute } = useNoRefreshCallbackStateRouteMixinFn()
  if (fromWebEditor) {
    // 延迟执行
    // const tid = setTimeout(() => {
    //   useSignUpSuccessTrialDialog()
    //   clearTimeout(tid)
    // }, 0)
    useSignUpSuccessTrialDialog()
  } else {
    setNoRefreshCallbackStateRoute()
  }
}
